<template>
    <BaseHeader
      :showReturnOldChessmateLink="false"
      shortStyle
      alwaysShowLanguageSwitcher
    />
    <Form/>
    <Footer/>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.min.css';
    import Form from '@/login/components/Form.vue'
    import Footer from '@/login/components/Footer.vue'
    import BaseHeader from '@/common/components/BaseHeader.vue'
    require('bootstrap');
    import 'material-design-icons/iconfont/material-icons.css';

    export default {
        name: 'App',
        components: {
          BaseHeader, Form, Footer
        },
        setup() {
            const url = new URL(window.location.href);
            const token = url.searchParams.get("token");
            if (token) {
                localStorage.setItem('token', token);
                window.location.href = process.env.VUE_APP_AFTER_LOGIN;
            }
        }
    }
</script>

<style lang="css" src="@/common/assets/css/Fonts.css"/>
