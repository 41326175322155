<template>
    <Modal :rightButtons="buttons" @clickButton="clickButton" @close="modalClose" ref="root" sizeClass="modal-lg">
        <template v-slot:title>
            {{ t('messages_full_list.title') }}
        </template>
        <template v-slot:body>
            <div class="text-end">
                <button type="button" class="btn btn-sm btn-outline-primary mb-2" @click="setReadAll">{{ t('messages_full_list.read_all' )}}</button>
            </div>
            <div v-if="!isLoading" class="accordion">
                <div v-for="message in messages" :key="message.id" class="accordion-item">
                    <h2 class="accordion-header" :id="'#messages-accordion-' + message.id">
                        <button @click="setRead(message)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#message-' + message.id" aria-expanded="false" :aria-controls="'message-' + message.id">
                            <div class="width-100 px-2 small" :class="[!message.read ? 'fw-bold' : '']">
                                <div class="d-inline-block width-25">
                                    <div v-if="message.sender">{{ message.sender.first_name }} <span v-if="message.sender.agency">({{ message.sender.agency.title }})</span></div>
                                </div>
                                <div class="d-inline-block width-50">{{ message.title }}</div>
                                <div class="d-inline-block width-25 text-end"> {{ dateHelper.getRelativeTime(message.created_at) }}</div>
                            </div>
                        </button>
                    </h2>
                    <div :id="'message-' + message.id" class="accordion-collapse collapse" :aria-labelledby="'#messages-accordion-' + message.id" data-bs-parent="#messages-accordion">
                        <div class="accordion-body small" v-html='stringHelper.getStringWithLink(message.content)'/>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <button type="button" class="btn btn-sm btn-primary" :disabled="currentPage == 1" @click="loadPrevious">←</button>
                    </div>
                    <div class="col text-end">
                        <button type="button" class="btn btn-sm btn-primary" :disabled="currentPage >= totalPages" @click="loadNext">→</button>
                    </div>
                </div>
            </div>
            <PreloaderModal v-if="isLoading"/>
        </template>
    </Modal>
</template>
<script>
    import { useI18n } from 'vue-i18n'
    import { ref, onMounted } from 'vue';
    import api from '@/common/api';
    import emitter from '@/common/emitter';
    import commonStore from '@/common/commonStore';
    import Modal from '@/common/components/Modal.vue';
    import PreloaderModal from '@/common/components/Preloader/PreloaderModal.vue';
    import stringHelper from '@/common/helpers/stringHelper';
    import dateHelper from '@/common/helpers/dateHelper';
    import amplitude from '@/common/amplitude';
    export default {
        name: 'MessagesFullList',
        components : {
            PreloaderModal,
            Modal
        },
        setup() {
            const { t } = useI18n();
            const root = ref(null);
            const messages = ref([]);
            const totalPages = ref(null);
            const currentPage = ref(1);
            const isLoading = ref(false);

            const open = (large = 'default') => {
                amplitude.track(amplitude.EVENT.NOTIFICATIONS_ALL_VIEW);
                root.value.open(large);
            }

            const modalClose = () => {}

            const buttons = [
                {
                    id : 'close',
                    title : t('messages_full_list.close_modal'),
                    class : 'btn-primary',
                },
            ];
            const clickButton = button => {
                if (button.id == 'close') {
                    root.value.close();
                }
            };

            const loadMessages = async () => {
                isLoading.value = true;
                try {
                    const data = await api.get({
                        url: 'messages',
                        query: {
                            page: currentPage.value
                        },
                    })
                    messages.value = [...data.messages];
                    totalPages.value = data.pagination.total_pages;
                    commonStore.commit('setUnreadMessagesCount', {count: data.unread_count});
                } catch {
                    //
                } finally {
                    isLoading.value = false;
                }
            };

            const setRead = async (message) => {
                if (!message.read) {
                    messages.value.map(m => {
                        if (m.id == message.id) {
                            m.read = true;
                        }
                    });
                    try {
                      await commonStore.dispatch('saveMessageRead', {id: message.id});
                    } catch {
                      //
                    }
                }
            }

            const setReadAll = async () => {
                try {
                    await api.put({
                        url: 'messages/read_all',
                        responseType: 'text',
                    })
                    messages.value.map(m => {
                        m.read = true;
                    });
                    commonStore.commit('setAllMessagesRead');
                } catch {
                    //
                }
            }

            const loadPrevious = () => {
                if (currentPage.value > 1) {
                    currentPage.value--;
                    loadMessages();
                }
            }

            const loadNext = () => {
                currentPage.value++;
                loadMessages();
            }

            onMounted(() => loadMessages());

            emitter.on('messagesFullLoad', () => loadMessages());

            return {
                t,
                root,
                isLoading,
                messages,
                buttons,
                clickButton,
                open,
                modalClose,
                currentPage,
                totalPages,
                stringHelper,
                dateHelper,
                setRead,
                setReadAll,
                loadPrevious,
                loadNext,
            }
        },
    }
</script>
<style scoped>
</style>
