<template>
    <Modal :leftButtons="leftButtons" :rightButtons="rightButtons" @clickButton="clickButton" @close="modalClose" ref="root" sizeClass="max-width-825">
        <template v-slot:title>
            {{ title }}
        </template>
        <template v-slot:title-action>
            <div v-if="!shareLink">
                <span v-if="isHistoryLoading" class="material-icons align-bottom me-1 rotating">autorenew</span>
                <a href="#" @click.prevent="toggleHistory">{{ t('cart.history_load') }}</a>
            </div>
        </template>
        <template v-slot:body>
            <div v-if="shareLink" class="d-flex">
                <input ref="linkInput" type="text" class="form-control form-control-sm me-3" :value="shareLink" autocomplete="off">
                <button type="button" class="btn btn-sm btn-light d-flex" @click="copy"><span class="material-icons me-2">content_copy</span>{{ t('cart.copy')}}</button>
            </div>
            <div v-if="!shareLink" class="small">
                <transition name="fade">
                    <div v-if="isHistory && isHistoryLoaded" class="mb-2">
                        <table v-if="history.length" class="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th scope="col">{{ t('cart.history_table_date') }}</th>
                                <th scope="col">{{ t('cart.history_table_phone') }}</th>
                                <th scope="col">{{ t('cart.history_table_email') }}</th>
                                <th scope="col">{{ t('cart.history_table_source') }}</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in history" :key="item.id">
                                    <td>{{ item.created_at }}</td>
                                    <td>{{ item.source.phone }}</td>
                                    <td>{{ item.source.email }}</td>
                                    <td>{{ item.source.from }}</td>
                                    <td><a href="#" @click.prevent="selectHistoryItem(item)">{{ t('cart.history_table_more') }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="d-flex align-items-center justify-content-start">
                                    <button type="button" class="btn btn-sm btn-primary" :disabled="currentHistoryPage == 1" @click="loadHistoryPrevious">←</button>
                                    <span v-if="isHistoryPrevLoading" class="material-icons align-bottom me-1 rotating">autorenew</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex align-items-center justify-content-end">
                                    <span v-if="isHistoryNextLoading" class="material-icons align-bottom me-1 rotating">autorenew</span>
                                    <button type="button" class="btn btn-sm btn-primary" :disabled="currentHistoryPage >= totalHistoryPages" @click="loadHistoryNext">→</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </transition>

                <div class="row">
                    <div class="col">
                        <div class="d-flex align-items-center">
                            <span class="me-3">{{t('cart.arrival')}}</span>
                            <DatePickerSimple
                                ref="DatePickerBegin"
                                :date="beginDate"
                                @update="updateDateBegin"
                            />
                        </div>
                        <div v-if="errors.begin_date" class="invalid-feedback d-block">{{ errors.begin_date }}</div>
                    </div>
                    <div class="col">
                        <div class="d-flex align-items-center">
                            <span class="me-3">{{t('cart.departure')}}</span>
                            <DatePickerSimple
                                ref="DatePickerEnd"
                                :date="endDate"
                                @update="updateDateEnd"
                            />
                        </div>
                        <div v-if="errors.end_date" class="invalid-feedback d-block">{{ errors.end_date }}</div>
                    </div>
                </div>

                <div v-for="apartment in apartments" :key="apartment.id" class="row my-3">
                    <div class="col-8 my-auto">
                        <span>{{ apartment.title }}</span>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-auto">
                                <input type="text" class="form-control form-control-sm max-width-95" v-model="apartment.amount" @input="amountUpdated(apartment.id)" autocomplete="off">
                            </div>
                            <span class="col-auto ps-0 pe-2 align-self-center">
                                {{ t('cart.amount') }}
                            </span>
                            <div class="col-auto">
                                <a href="#" @click.prevent="remove(apartment.id)"><span class="material-icons line-height-unset">highlight_off</span></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="errors.apartments" class="alert alert-danger p-2 mt-2">
                    {{ errors.apartments }}
                </div>

                <hr />

                <div class="row flex-lg-nowrap">
                    <div class="col-md-auto pe-0">
                        <div class="mb-3 row">
                            <label for="cart-email" class="col-sm-2 col-form-label col-form-label-sm">{{ t('cart.email') }}</label>
                            <div class="col-sm-10 max-width-200">
                                <input v-model="email" type="text" class="form-control form-control-sm" :class="[errors.email ? 'is-invalid' : '']" id="cart-email" autocomplete="off">
                                <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-auto pe-0">
                        <div class="mb-3 row">
                            <label for="cart-sms" class="col-sm-2 col-form-label col-form-label-sm">{{ t('cart.sms') }}</label>
                            <div class="col-sm-10 max-width-200">
                                <input v-model="phone" v-maska="'+7 (###) ###-##-##'" class="form-control form-control-sm" :class="[errors.phone ? 'is-invalid' : '']" id="cart-sms">
                                <div v-if="errors.phone" class="invalid-feedback">{{ errors.phone }}</div>
                                <div class="small">{{ t('cart.sms_left', smsCount) }} <a href="/billing/sms"  class="small" target="_blank">{{ t('cart.buy_more') }}</a></div>
                            </div>
                        </div>
                    </div>
                    <NativeSelect
                        :className="{wrapper: 'col-md-auto pe-0', label: 'lh-120 col-sm-5 pe-0', select: 'col-sm-7 ps-0 max-width-170'}"
                        :label="t('cart.lifetime')"
                        :options="lifetimeOptions"
                        v-model:value="lifetime"
                    />
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
    import { useI18n } from 'vue-i18n'
    import { ref, reactive, onMounted, computed, watch } from 'vue';
    import api from '@/common/api';
    import emitter from '@/common/emitter';
    import Modal from '@/common/components/Modal.vue';
    import DatePickerSimple from "@/common/components/DatePickerSimple";
    import dateHelper from '@/common/helpers/dateHelper';
    import commonStore from '@/common/commonStore';
    import NativeSelect from '@/common/components/inputs/NativeSelect';
    import amplitude from '@/common/amplitude';
    export default {
        name: 'Cart',
        components : {
            NativeSelect,
            DatePickerSimple,
            Modal
        },
        setup() {
            const { t } = useI18n();
            const root = ref(null);
            const title = ref(t('cart.title'));
            const isGetLinking = ref(false);
            const isSending = ref(false);
            const isHistory = ref(false);
            const isHistoryLoaded = ref(false);
            const isHistoryLoading = ref(false);
            const isHistoryPrevLoading = ref(false);
            const isHistoryNextLoading = ref(false);
            const totalHistoryPages = ref(null);
            const currentHistoryPage = ref(1);
            const history = ref([]);
            const beginDate = ref(null);
            const endDate = ref(null);
            const email = ref(null);
            const phone = ref(null);
            const DatePickerBegin = ref(null);
            const DatePickerEnd = ref(null);
            const errors = reactive({
                begin_date : null,
                end_date : null,
                apartments : null,
                email : null,
                phone : null,
            });
            const shareLink = ref(null);
            const linkInput = ref(null);

            const lifetime = ref(0);
            const LIFETIME_DAYS = 7;
            const lifetimeOptions = [];

            for (let i = 0; i <= LIFETIME_DAYS; i++) {
                lifetimeOptions.push(
                    {
                        name: t(`cart.lifetime_days`, {count: i}),
                        id: i
                    },
                )
            }

            const apartments = ref([]);

            const smsCount = computed(() => commonStore.state.smsCount);

            const open = (size = 'default') => {

                email.value = null;
                phone.value  = null;
                lifetime.value = 0;

                for (let k in errors) {
                    errors[k] = null;
                }
                shareLink.value = null;

                root.value.open(size);
            }

            const modalClose = () => {}

            const leftButtons = computed(() => shareLink.value ? [] : [
                {
                    id : 'clear',
                    title : t('cart.clear'),
                    class : 'btn-light',
                },
            ]);

            const rightButtons = computed(() => shareLink.value ? [
                    {
                        id : 'close',
                        title : t('cart.close'),
                        class : 'btn-primary',
                        disabled : isGetLinking.value,
                    },
                ] : [
                    {
                        id : 'get_link',
                        title : t('cart.get_link'),
                        class : 'btn-primary',
                        disabled : isGetLinking.value,
                    },
                    {
                        id : 'send',
                        title : t('cart.send'),
                        class : 'btn-primary',
                        disabled : isSending.value,
                    },
                ]
            );

            const clickButton = button => {
                if (button.id == 'clear') {
                    unSelectAll();
                } else if (button.id == 'get_link') {
                    getLink();
                } else if (button.id == 'send') {
                    send();
                } else if (button.id == 'close') {
                    root.value.close();
                }
            };

            const setShareMode = (link) => {
                shareLink.value = link;
                title.value = t('cart.title_link');
                setTimeout(() => copy(), 0);
            }

            const loadPrice = async (id) => {
                const begin = dateHelper.formatDateString(beginDate.value);
                const end = dateHelper.formatDateString(endDate.value);
                try {
                    const data = await api.get(`apartments/${id}/price?begin_date=${begin}&end_date=${end}`)
                    const apartment = apartments.value.find(a => a.id == id);
                    if (apartment && !apartment.userUpdated) {
                        apartment.amount = Math.trunc(data.price / dateHelper.daysDiff(beginDate.value, endDate.value));
                    }
                } catch {
                  //
                }
            }

            const loadAllPrices = () => {
                if (!issetDates()) return;
                apartments.value.forEach(a => {
                    loadPrice(a.id);
                })
            }

            const toggleHistory = () => {
                if (!isHistoryLoaded.value) {
                    isHistory.value = true;
                    loadHistory();
                } else {
                    isHistory.value = !isHistory.value;
                }
            }

            const loadHistoryPrevious = () => {
                if (currentHistoryPage.value > 1) {
                    currentHistoryPage.value--;
                    isHistoryPrevLoading.value = true;
                    loadHistory();
                }
            }

            const loadHistoryNext = () => {
                currentHistoryPage.value++;
                isHistoryNextLoading.value = true
                loadHistory();
            }

            const selectHistoryItem = (item) => {
                amplitude.track(amplitude.EVENT.BASKET_HISTORY_ITEM_APPLY);
                beginDate.value = dateHelper.parseDate(item.source.begin_date);
                endDate.value = dateHelper.parseDate(item.source.end_date);
                email.value = item.source.email;
                phone.value = item.source.phone;

                unSelectAll();

                item.source.items.forEach(i => {
                    apartments.value.push({
                        id : i.apartment_id,
                        title : i.apartment_title,
                        amount: i.amount,
                        userUpdated: i.is_special_amount,
                    });
                });
            }

            const remove = (id) => {
                unSelect(id);
                emitter.emit('unSelectApartment', {
                    from: 'cart',
                    id: id,
                    count: apartments.value.length,
                });
            }

            const unSelect = (id) => {
                apartments.value = [...apartments.value.filter(a => a.id != id)];
            }

            const unSelectAll = () => {
                apartments.value = [];
                emitter.emit('unSelectAllApartments');
            }

            const updateDateBegin = (e) => {
                errors.begin_date = null;
                if (e.date) {
                    beginDate.value = e.date;
                    endDate.value = dateHelper.calculateDateEnd(e.date, endDate.value ? endDate.value : null);
                    loadAllPrices();
                }
            }

            const updateDateEnd = (e) => {
                errors.end_date = null;
                if (e.date) {
                    endDate.value = e.date;
                    beginDate.value = dateHelper.calculateDateBegin(beginDate.value ? beginDate.value : null, e.date);
                    loadAllPrices();
                }
            }

            const loadHistory = async () => {
                if (!isHistoryLoaded.value) {
                    isHistoryLoading.value = true;
                }
                try {
                    const data = await api.get({
                      url: 'carts/history',
                      query: {
                          page: currentHistoryPage.value
                      },
                    })
                    totalHistoryPages.value = data.paginatitotal_pages;
                    history.value = [...data.baskets];
                } catch {
                    //
                } finally {
                    isHistoryLoaded.value = true;
                    isHistoryLoading.value = false;
                    isHistoryPrevLoading.value = false;
                    isHistoryNextLoading.value = false;
                }
            }

            const prepareData = (withContacts = false) => {
                let cart = {
                    begin_date: beginDate.value ? dateHelper.formatDateString(beginDate.value) : null,
                    end_date: endDate.value ? dateHelper.formatDateString(endDate.value) : null,
                    items: [],
                    lifetime: lifetime.value,
                };
                if (withContacts) {
                    cart.email = email.value;
                    cart.phone = phone.value;
                }
                apartments.value.forEach(a => {
                    cart.items.push({
                        apartment_id: a.id,
                        apartment_title: a.title,
                        amount: a.amount,
                        is_special_amount: a.userUpdated
                    });
                });
                return {cart};
            }

            const createErrorsFromRespone = (reponseErrors) => {
                errors.begin_date = reponseErrors.begin_date ? reponseErrors.begin_date[0] : null;
                errors.end_date = reponseErrors.end_date ? reponseErrors.end_date[0] : null;
                errors.apartments = reponseErrors.items ? reponseErrors.items[0] : null;
                errors.email = reponseErrors.email ? reponseErrors.email[0] : null;
                errors.phone = reponseErrors.phone ? reponseErrors.phone[0] : null;
            }

            const getLink = async () => {
              isGetLinking.value = true;
              try {
                  const data = await api.post({
                      url: 'carts/copy_link',
                      data: prepareData(),
                  })
                  amplitude.track(amplitude.EVENT.BASKET_GETLINK);
                  setShareMode(data.basket.short_url);
                  unSelectAll();
              } catch (err) {
                  if (err.isValidation) {
                      createErrorsFromRespone(err.errors);
                  }
              } finally {
                  isGetLinking.value = false;
              }
            }

            const send = async () => {
                isSending.value = true;
                try {
                    const data = prepareData(true);
                    await api.post({
                        url: 'carts',
                        data: data,
                    })
                    const trackActions = [];
                    if (data.cart.email) {
                        trackActions.push('email');
                    }
                    if (data.cart.phone) {
                        trackActions.push('sms');
                    }
                    amplitude.track(amplitude.EVENT.BASKET_SEND, {action: trackActions.join(', ')});
                    await commonStore.dispatch('loadSMSCount');
                    unSelectAll();
                    root.value.close();
                } catch (err) {
                    if (err.isValidation) {
                        createErrorsFromRespone(err.errors);
                    }
                } finally {
                    isSending.value = false;
                }
            }

            const issetDates = () => beginDate.value && endDate.value;

            onMounted(() => {

                emitter.on('updateFilterDates', e => {
                    beginDate.value = e.beginDate;
                    endDate.value = e.endDate;
                    loadAllPrices();
                });

                emitter.on('selectApartment', (event) => {
                    if (apartments.value.find(a => a.id == event.apartment.id)) return;
                    if (issetDates()) {
                        event.apartment.amount = null;
                    }
                    event.apartment.userUpdated = false;
                    apartments.value = [...apartments.value, event.apartment];
                    if (issetDates()) {
                        loadPrice(event.apartment.id);
                    }
                });
                emitter.on('unSelectApartment', (event) => {
                    if (event.from == 'cart') return;
                    unSelect(event.id);
                });

            });

            const amountUpdated = (id) => {
                apartments.value = [...apartments.value.map(a => {
                    if (a.id == id) {
                        a.userUpdated = true;
                    }
                    return a;
                })];
            }

            const copy = () => {
              if (linkInput.value) {
                linkInput.value.select();
                document.execCommand('copy');
              }
            }

            watch(email, () => {errors.email = null});
            watch(phone, () => {errors.phone = null});

            watch(isHistory, (val) => {
                if (val) {
                    amplitude.track(amplitude.EVENT.BASKET_HISTORY_VIEW);
                }
            })

            return {
                t,
                root,
                title,
                leftButtons,
                rightButtons,
                clickButton,
                open,
                modalClose,
                beginDate,
                endDate,
                history,
                isHistory,
                isHistoryLoaded,
                isHistoryLoading,
                toggleHistory,
                selectHistoryItem,
                smsCount,
                apartments,
                remove,
                updateDateBegin,
                updateDateEnd,
                currentHistoryPage,
                totalHistoryPages,
                loadHistoryPrevious,
                loadHistoryNext,
                isHistoryPrevLoading,
                isHistoryNextLoading,
                email,
                phone,
                DatePickerBegin,
                DatePickerEnd,
                errors,
                amountUpdated,
                shareLink,
                linkInput,
                copy,
                lifetime,
                lifetimeOptions,
            }
        },
    }
</script>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
    .max-width-95 {
        max-width: 95px;
    }
</style>
