import { createStore } from 'vuex';
import commonStore from '@/common/commonStore';
import amplitude from '@/common/amplitude';

const signUpStore = createStore({
    getters: {
        shortTempUserId() {
            return `auth-${commonStore.getters.tempUserId.split('-').slice(0, 3).join('')}`;
        },
    },
    actions: {
        async init() {
            commonStore.commit('initTempUserId');
            await amplitude.init();
        },
    },
});

export default signUpStore;
