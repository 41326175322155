import * as Sentry from '@sentry/vue';

const isActive = process.env.VUE_APP_SENTRY_ACTIVE === 'true'

const init = (app, router = null) => {
  if (isActive) {
    Sentry.init({
      app,
      release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
      environment: process.env.VUE_APP_ENVIRONMENT,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router })],
      tracesSampleRate: 1.0,
    });
  }
}

const setUser = (user) => {
  if (isActive) {
    Sentry.setUser({
      id: user.id,
      username: user.full_name,
      email: user.email,
      agency_id: user.agency.account.number,
      user_phone: user.telephone,
    });
  }
}

const track = (message) => {
    if (!isActive) return;
    Sentry.captureMessage(message);
}

export default {init, setUser, track}
