import numberHelper from '@/common/helpers/numberHelper'
import emitter from '@/common/emitter';
export default function useToast () {
  const show = (payload) => {
    const toast = {
      key: numberHelper.getRandomNumber(),
      type: payload.type || '',
      text: payload.text,
      time: payload.time || 5000,
    }
    emitter.emit('showToast', toast);
  }
  return {
    show
  }
}
