export default function useEnsurePromise () {
  let resolve = null;
  let reject = null;

  const ensurePromise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  })

  return {
    resolve,
    reject,
    ensurePromise
  }
}
