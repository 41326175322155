<template>
    <div class="mt-3 text-center">© {{ (new Date()).getFullYear() }} RealtyCalendar</div>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>

<style scoped>

</style>
