<template>
  <div class="d-flex justify-content-center align-items-center">
    <select
      :disabled="props.disabled"
      v-model="selectedHour"
      class="select form-select form-select-sm me-1"
    >
      <option
        :key="hour"
        :value="hour"
        v-for="hour in hours"
      >
        {{ hour }}
      </option>
    </select>

    <span>:</span>

    <select
      :disabled="props.disabled"
      v-model="selectedMinute"
      class="select form-select form-select-sm ms-1"
    >
      <option
        :key="minute"
        :value="minute"
        v-for="minute in minutes"
      >
        {{ minute }}
      </option>
    </select>
  </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import dateHelper from '@/common/helpers/dateHelper';

const props = defineProps({
  step: {
    type: Number,
    default: 1,
  },
  value: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:value']);

const emptyTime = '--';

const hours = [emptyTime];
const minutes = [emptyTime];

const selectedHour = ref(emptyTime);
const selectedMinute = ref(emptyTime);

for (let i = 0; i <= 23; i++) {
  hours.push(i < 10 ? '0' + i : i.toString());
}

// eslint-disable-next-line vue/no-setup-props-destructure
for (let i = 0; i <= 59; i += props.step) {
  minutes.push(i < 10 ? '0' + i : i.toString());
}

const time = computed(() => {
  const currentTime = `${selectedHour.value}:${selectedMinute.value}`;
  if (dateHelper.validateTimeString(currentTime)) {
    return currentTime;
  } else {
    return null;
  }
});

watch(time, (value) => {
  emit('update:value', value);
});

watch(() => props.value, (time) => {
  if (dateHelper.parseTimeString(time)) {
    const arr = time.split(':');
    selectedHour.value = arr[0].length === 1 ? `0${arr[0]}` : arr[0];
    selectedMinute.value = arr[1];
  }
});

watch(selectedHour, (hour) => {
  if (hour !== emptyTime && selectedMinute.value === emptyTime) {
    selectedMinute.value = minutes[1];
    return;
  }
  if (hour === emptyTime && selectedMinute.value !== emptyTime) {
    selectedMinute.value = minutes[0];
  }
});

watch(selectedMinute, (minute) => {
  if (minute === emptyTime && selectedHour.value !== emptyTime) {
    selectedHour.value = hours[0];
  }
});
</script>

<style scoped>
.select {
  width: 40px;
  padding-right: 4px;
  padding-left: 4px;
  background-image: none;
  text-align: center;
  cursor: pointer;
}
</style>
