<template>
  <div class="preloader">
    <div class="loading">
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  </div>
</template>

<script setup>
</script>
<style>
.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 112px;
  height: 36px;
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
}

.loading div {
  position: absolute;
  width: 16px;
  background: silver;
  animation: loading 1.0s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loading div:nth-child(1) {
  left: 0;
  animation-delay: -0.44s;
}

.loading div:nth-child(2) {
  left: 24px;
  animation-delay: -0.32s;
}

.loading div:nth-child(3) {
  left: 48px;
  animation-delay: -0.22s;
}

.loading div:nth-child(4) {
  left: 72px;
  animation-delay: -0.12s;
}

.loading div:nth-child(5) {
  left: 96px;
  animation-delay: 0s;
}

@keyframes loading {
  0% {
    top: 3px;
    height: 34px;
  }
  50%, 100% {
    top: 12px;
    height: 16px;
  }
}
</style>
