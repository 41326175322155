<template>
    <div class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div>
                <a
                  v-if="shortStyle"
                  class="navbar-logo"
                  :href="appBaseUrl"
                >
                    <icon-logo full/>
                </a>
                <span class="navbar-text align-middle" id="phone">
                    <a href="tel:74993489068">+7 (499) 348-90-68</a>
                    <span>{{ t('navbar.work_time') }}</span>
                </span>
                <span v-if="isZadarma" class="navbar-text" id="call">
                    <span id="myZadarmaCallmeWidget3918"></span>
                </span>
                <span v-if="isReturnOldChessmateLink" class="navbar-text ms-3 return-old">
                    <span class="material-icons align-bottom">settings_backup_restore</span>
                    <div>
                        <a href="#" @click.prevent="returnToOldChessmate">{{ t('navbar.return_to_old_chessmate')}}</a>
                    </div>
                </span>
            </div>
            <div>
                <div v-if="isLoading" class="align-middle spinner-border text-primary spinner-border-sm"></div>

                <button v-if="isActiveTranslations" class="btn text-nowrap mx-3" @click="changeLocale">
                  <span class="material-icons fs-5 align-text-top me-1">language</span> {{ $i18n.locale.toUpperCase() }}
                </button>

                <button v-if="!shortStyle" type="button" class="btn mx-3" id="messages" @click.prevent="openShortList">
                    <span class="material-icons align-middle">notifications_none</span>
                    <span v-if="unreadMessagesCount" class="align-middle me-1">{{ unreadMessagesCount }}</span>
                    <svg v-if="unreadMessagesCount" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" fill="#FF0000"/>
                    </svg>
                </button>

                <button v-if="!shortStyle" ref="cartButton" type="button" class="btn  mx-3" @click.prevent="openCart">
                    <span class="material-icons align-middle mx-1">{{ hasCartApartments ? 'mail' : 'mail_outline' }}</span>
                    <span class="align-middle">{{ t('navbar.cart') }}</span>
                </button>

                <div v-if="!shortStyle" class="btn-group" role="group">
                    <button type="button" class="btn pe-0" @click.prevent="addExpense">
                        <span class="add-icon align-middle me-1"></span>
                    </button>
                    <button type="button" id="btnGroupAdd" class="btn ps-0 dropdown-toggle dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="align-middle me-1">{{ t('navbar.add') }}...</span>
                        <span class="material-icons align-bottom">expand_more</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupAdd">
                        <li><a class="dropdown-item" :href="urlAddApartment">{{ t('navbar.add_apartment') }}</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="addExpense">{{ t('navbar.add_expense') }}</a></li>
                    </ul>
                </div>

                <div v-if="!shortStyle" class="btn-group" role="group">
                    <button id="btnGroupUser" type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="material-icons align-middle mx-1">person_outline</span>
                        <span id="agency-number" class="align-middle">{{ agencyNumber }}</span>
                        <span class="material-icons align-bottom">expand_more</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupUser">
                        <li v-if="urlLoginByManager"><a class="dropdown-item" href="#" @click.prevent="loginAsManager">{{ t('navbar.login_by_manager') }}</a></li>
                        <li><a class="dropdown-item" :href="urlSettings">{{ t('navbar.settings') }}</a></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="logout">{{ t('navbar.logout') }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!shortStyle" class="null-block">
        <MessagesShortList ref="messagesShortList" :messagesFullList="messagesFullList"></MessagesShortList>
        <MessagesFullList ref="messagesFullList"></MessagesFullList>
        <Cart ref="cart"></Cart>
        <Expense ref="expense"></Expense>
    </div>
</template>

<script>
    import { useI18n } from 'vue-i18n';
    import {ref, computed, onMounted} from 'vue';
    import {changeLocale, loadCSSUrl, loadJSUrl} from '@/common/init';
    import emitter from '@/common/emitter';
    import commonStore from '@/common/commonStore';
    import MessagesShortList from "@/common/components/messages/MessagesShortList";
    import MessagesFullList from "@/common/components/messages/MessagesFullList";
    import Cart from "@/common/components/Cart";
    import Expense from "@/common/components/Expense";
    import api from '@/common/api';
    import initNavbarCable from '@/common/initNavbarCable';
    import IconLogo from '@/common/components/icons/IconLogo';
    import amplitude from '@/common/amplitude';
    export default {
        name: 'BaseHeader',
        components: {
          IconLogo,
            Expense,
            Cart,
            MessagesFullList,
            MessagesShortList,
        },
        props: {
          shortStyle: {
              type: Boolean,
              default: false,
          },
          alwaysShowLanguageSwitcher: {
              type: Boolean,
              default: false
          }
        },
        setup(props) {
            const { t, locale } = useI18n();
            const messagesShortList = ref(null);
            const messagesFullList = ref(null);
            const expense = ref(null);
            const cart = ref(null);
            const cartButton = ref(null);
            const hasCartApartments = ref(false);

            const appBaseUrl = process.env.VUE_APP_BASE_URL;

            const urlOldChessmate = computed(() => '/agencies/{ID}/apartments'.replace('{ID}', commonStore.getters.agencyId))

            const isZadarma = process?.env?.NODE_ENV && (['production', 'stage'].indexOf(process.env.NODE_ENV) > -1);
            if (isZadarma) {
                import("@/common/assets/css/Zadarma.css");
            }

            const currentPage = commonStore.state.currentPage;

            const setZadarama = () => {
                const onLoaded = () => {
                    /* global ZadarmaCallmeWidget */
                    window.myZadarmaCallmeWidget3918 = new ZadarmaCallmeWidget("myZadarmaCallmeWidget3918");
                    window.myZadarmaCallmeWidget3918.create({
                        "widgetId": "bEkEMdr6653aXkhjds2gya8cueC5pK43z7a1ypKJ2EKmVeHxra3965Bx8bTz9Hpgsnvk18PrkezaRDeXNTbah7xg83TvHuS64c1c794724d96f1c40b10001bd510e6f",
                        "sipId": "540951",
                        "domElement": "myZadarmaCallmeWidget3918"
                    }, {
                        "shape": "square",
                        "language": locale.value,
                        "width": "0",
                        "dtmf": false,
                        "font": "Ubuntu,'Trebuchet MS','Helvetica CY',sans-serif",
                        "color_call": "rgb(13,110,253)",
                        "color_bg_call": "rgb(252,252,252)",
                        "color_border_call": "rgb(255,255,255)",
                        "color_connection": "rgb(255, 255, 255)",
                        "color_bg_connection": "rgb(33, 211, 166)",
                        "color_border_connection": "rgb(144, 233, 211)",
                        "color_calling": "rgb(255, 255, 255)",
                        "color_border_calling": "rgb(255, 218, 128)",
                        "color_bg_calling": "rgb(255, 181, 0)",
                        "color_ended": "rgb(255, 255, 255)",
                        "color_bg_ended": "rgb(164,164,164)",
                        "color_border_ended": "rgb(210, 210, 210)"
                    });
                }

                const jsUrls = [
                    'https://my.zadarma.com/callmewidget/v2.0.8/detectWebRTC.min.js',
                    'https://my.zadarma.com/callmewidget/v2.0.8/jssip.min.js',
                    'https://my.zadarma.com/callmewidget/v2.0.8/widget.min.js'
                ];
                const cssUrl = 'https://my.zadarma.com/callmewidget/v2.0.8/style.min.css';

                loadCSSUrl(cssUrl);
                let loadedJSUrlsCount = 0;
                jsUrls.forEach(url => loadJSUrl(url, () => {
                    loadedJSUrlsCount++;
                    if (loadedJSUrlsCount === jsUrls.length) {
                        onLoaded();
                    }
                }));
            }


            const onUpdateSelectedApartments = (count) => {
                hasCartApartments.value = !!count;
                cartButton.value.classList.add('animation-opacity-0');
                setTimeout(() => {
                    cartButton.value.classList.remove("animation-opacity-0");
                }, 500);
            }

            onMounted(() => {

                if (!props.shortStyle) {
                    initNavbarCable();
                    emitter.on('selectApartment', (event) => onUpdateSelectedApartments(event.count));
                    emitter.on('unSelectApartment', (event) => onUpdateSelectedApartments(event.count));
                    emitter.on('unSelectAllApartments', () => onUpdateSelectedApartments(0));
                }

                if (isZadarma) {
                    setZadarama();
                }

            });

            const openShortList = (e) => {
                amplitude.track(amplitude.EVENT.NOTIFICATIONS_RECENT_VIEW);
                messagesShortList.value.open(e.target, 'bottom', true);
            }

            const openCart = () => {
                amplitude.track(amplitude.EVENT.BASKET_VIEW);
                cart.value.open();
            }

            const addExpense = () => {
                expense.value.open();
            }

            const logout = async () => {
                if (confirm(t('navbar.logout_confirm'))) {
                    try {
                        await api.del(commonStore.getters.hasFeatureFlag('v2_sign_up') ? 'sign_in' : 'users/sign_out');
                    } catch {
                        //
                    }
                    finally {
                        localStorage.removeItem('token');
                        window.location.href = commonStore.getters.hasFeatureFlag('v2_sign_up')
                            ? process.env.VUE_APP_AUTH_URL
                            : process.env.VUE_APP_LOGIN_URL;
                    }
                }
            }

            const urlLoginByManager = commonStore.state.user?.login_as_manager;

            const loginAsManager = async () => {
              try {
                const data = await api.post(urlLoginByManager);
                localStorage.setItem('token', data.token);
                location.reload();
              } catch {
                //
              }
            }

            const returnToOldChessmate = async () => {
              await amplitude.track(amplitude.EVENT.CHESSMATE_OLD_CLICK);
              window.location.href = urlOldChessmate.value;
            }

            return {
                isZadarma,
                appBaseUrl,
                t,
                isLoading: computed(() => commonStore.getters.isLoading),
                user: computed(() => commonStore.state.user),
                unreadMessagesCount: computed(() => commonStore.state.unreadMessagesCount),
                agencyNumber: computed(() => commonStore.getters.agencyNumber),
                messagesShortList,
                messagesFullList,
                expense,
                cart,
                cartButton,
                openShortList,
                openCart,
                hasCartApartments,
                addExpense,
                logout,
                urlLoginByManager,
                loginAsManager,
                urlAddApartment: computed(() => commonStore.getters.urlAddApartment),
                urlSettings: computed(() => commonStore.getters.hasFeatureFlag('settings_v2') ? '/settings' : '/agencies/{ID}'.replace('{ID}', commonStore.getters.agencyId)),
                isReturnOldChessmateLink: computed(() => {
                    if (currentPage !== 'chessmate') {
                        return false;
                    } else {
                        return !commonStore.getters.hasFeatureFlag('not_return_old_chessmate');
                    }
                }),
                isActiveTranslations: computed(() => commonStore.getters.hasFeatureFlag('translation') || props.alwaysShowLanguageSwitcher),
                changeLocale,
                returnToOldChessmate,
            };
        },
    }
</script>
<style scoped lang="css" src="@/common/assets/css/Header.css"></style>

