<template>
    <div class="container-fluid">
        <div class="row justify-content-md-center mt-4">
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body bg-light">
                        <div class="text-center">
                            <h1 class="card-title mb-5">{{ t('form.login') }}</h1>
                        </div>

                        <div class="mb-3 row text-end">
                            <label for="login" class="col-sm-5 col-form-label">{{ t('form.phone_or_email') }}</label>
                            <div class="col-sm-7">
                                <input v-on:keydown.enter="send" v-model="login" type="text" class="form-control" :class="[error ? 'is-invalid' : '']" id="login">
                                <div v-if="error" class="invalid-feedback text-start">{{ error }}</div>
                            </div>
                        </div>

                        <div class="mb-3 row text-end">
                            <label for="password" class="col-sm-5 col-form-label">{{ t('form.password') }}</label>
                            <div class="col-sm-7">
                                <input v-on:keydown.enter="send" v-model="password" type="password" class="form-control" id="password">
                            </div>
                        </div>

                        <!--
                        <div class="mb-3 row">
                            <div class="col-sm-7 offset-sm-5">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="remembeMe">
                                    <label class="form-check-label" for="remembeMe">
                                        {{ t('form.remembe_me') }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        -->

                        <div class="text-center">
                            <button type="button" class="btn btn-primary" :class="[isLoading ? 'disabled' : '']" @click="send">{{ t('form.login_button') }}</button>
                        </div>
                    </div>
                </div>

                <ul class="list mt-3">
                    <li><a class="text-decoration-none" href="/registration">{{ t('form.registration') }}</a></li>
                    <li><a class="text-decoration-none" href="/users/password/new">{{ t('form.forgot_password') }}</a></li>
                    <li><a class="text-decoration-none" href="/users/sms_activation/new">{{ t('form.no_sms') }}</a></li>
                    <li><a class="text-decoration-none" href="/users/confirmation/new">{{ t('form.no_email') }}</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { useI18n } from 'vue-i18n'
    import { ref } from 'vue'
    import api from '@/common/api';
    export default {
        name: 'Form',
        setup() {
            const { t } = useI18n();
            const isLoading = ref(false);
            const login = ref(null);
            const password = ref(null);
            const error = ref(null);

            const send = async () => {
              error.value = '';
              isLoading.value = true;
              const formData = new FormData();
              formData.append(login.value && login.value.indexOf('@') > -1 ? 'email' : 'phone', login.value);
              formData.append('password', password.value);
              try {
                const data = await api.postNotAuth({
                  url: 'users/sign_in',
                  data: formData,
                })
                localStorage.setItem('token', data.token)
                if (localStorage.getItem('user-action') === null) {
                  localStorage.setItem('user-action', 'login');
                }
                window.location.href = process.env.VUE_APP_AFTER_LOGIN;
              } catch (err) {
                error.value = err.isValidation ? err.errors[0] : t('form.unknown_error');
              } finally {
                isLoading.value = false;
              }
            }
            return {
                t,
                isLoading,
                login,
                password,
                error,
                send,
            }
        }
    }
</script>

<style scoped>
    .list {
        list-style-type: none;
    }
</style>
