import { createConsumer } from '@rails/actioncable';

const consumer = createConsumer(`${process.env.VUE_APP_CABLE_URL}?token=${localStorage.getItem('token')}`);
const subscriptions = {};
const eventHandlers = {};

class Cable {
    constructor(channelName) {
        this.channelName = channelName;
    }

    subscribe(eventName, callback) {
        eventHandlers[this.channelName] ||= {}
        eventHandlers[this.channelName][eventName] = callback;
        subscriptions[this.channelName] ||= consumer.subscriptions.create(
            this.channelName,
            {
                received: this.combinedEventHandler.bind(this),
            }
        );

        // returning `this` needs for chained calls like:
        //   cable(...).subscribe(...).subscribe(...)
        return this
    }

    removeEventHandlers(event) {
        if (Array.isArray(event)) {
            event.forEach(e => delete eventHandlers[this.channelName][e])
        } else {
            delete eventHandlers[this.channelName][event]
        }
    }

    combinedEventHandler({ event, data }) {
        const handler = eventHandlers[this.channelName][event];

        if (handler !== undefined) {
            handler(data);
        }
    }
}

// wrapper needs for chained calls like:
//   cable('SomeChannelName').subscribe(...)
export default channelName => new Cable(channelName)
