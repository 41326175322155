const getRandomNumber = (max = 100000) => {
  return Math.floor(Math.random() * max);
}

const getTimeStamp = () => {
  return +new Date();
}

export default {
  getRandomNumber,
  getTimeStamp
}
