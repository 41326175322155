import commonStore from '@/common/commonStore';
import emitter from '@/common/emitter';
import cable from '@/common/actionCable';

export default function initNavbarCable() {
  cable('MessagesChannel').subscribe('refresh', () => {
    commonStore.dispatch('loadLastMessages');
    emitter.emit('messagesFullLoad');
  })
}
