<template>
    <div v-if="isOpened" ref="root" class="messages-short-list">
        <h6><span class="material-icons align-middle">notifications_none</span> {{ t('messages_short_list.new_count', unreadCount)}}</h6>
        <div v-if="messages.length" class="accordion">
            <div v-for="message in messages" :key="message.id" class="accordion-item">
                <h2 class="accordion-header" :id="'#messages-accordion-' + message.id">
                    <button @click="setRead(message)" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#message-' + message.id" aria-expanded="false" :aria-controls="'message-' + message.id">
                        <div class="width-100 px-2 small" :class="[!message.read ? 'fw-bold' : '']">
                            <div class="d-inline-block width-25">
                                <div v-if="message.sender">{{ message.sender.first_name }} <span v-if="message.sender.agency">({{ message.sender.agency.title }})</span></div>
                            </div>
                            <div class="d-inline-block width-50">{{ message.title }}</div>
                            <div class="d-inline-block width-25 text-end"> {{ dateHelper.getRelativeTime(message.created_at) }}</div>
                        </div>
                    </button>
                </h2>
                <div :id="'message-' + message.id" class="accordion-collapse collapse" :aria-labelledby="'#messages-accordion-' + message.id" data-bs-parent="#messages-accordion">
                    <div class="accordion-body" v-html='stringHelper.getStringWithLink(message.content)'/>
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-outline-primary mt-2 width-100" @click="close">{{ t('messages_short_list.unread_count', unreadCount )}} →</button>
        </div>
        <div v-else>
            {{ t('messages_short_list.no_messages')}}
        </div>
    </div>
</template>
<script>
    import { useI18n } from 'vue-i18n'
    import { ref, computed } from 'vue';
    import commonStore from '@/common/commonStore';
    import usePopover from '@/common/api/usePopover';
    import stringHelper from '@/common/helpers/stringHelper';
    import dateHelper from '@/common/helpers/dateHelper';

    export default {
        name: 'MessagesShortList',
        props : [
            'messagesFullList',
        ],
        setup( props ) {
            const { t } = useI18n();
            const messages = computed(() => commonStore.state.lastMessages);
            const unreadCount = computed(() => commonStore.state.unreadMessagesCount);
            const isOpened = ref(false);
            const popover = ref(null);
            const root = ref(null);
            const { open, close } = usePopover(root, popover, isOpened);

            const setRead = async (message) => {
                if (!message.read) {
                  try {
                    await commonStore.dispatch('saveMessageRead', {id: message.id});
                  } catch {
                    //
                  }
                }
            }
            return {
                t,
                isOpened,
                root,
                open,
                messages,
                unreadCount,
                stringHelper,
                setRead,
                dateHelper,
                close:  () => {
                    close();
                    setTimeout(props.messagesFullList.open(), 0);
                },
            }
        }
    }
</script>
<style scoped>
    .messages-short-list {
        width: 600px;
    }
    .accordion-button {
        padding: 0.3rem 0.5rem;
    }
</style>





