<template>
    <Modal :rightButtons="buttons" @clickButton="clickButton" @close="modalClose" ref="root">
        <template v-slot:title>
            {{ t('expense.title') }}
        </template>
        <template v-slot:body>

            <div class="me-5 small">
                <div class="row mb-3">
                    <label class="col-4 col-form-label col-form-label-sm">{{ t('expense.date') }}</label>
                    <div class="col-8">
                        <DatePicker :title="false" ref="DatePickerDate" name="paid_date" :error="errors.paid_date" maxDateWidth="120px" :isSmall="true"></DatePicker>
                    </div>
                </div>
                <div class="row">
                    <label class="col-4 col-form-label col-form-label-sm">{{ t('expense.sum') }}</label>
                    <div class="col-8">
                        <div class="d-flex align-items-center">
                            <input v-model="expense.amount" type="number" class="form-control form-control-sm text-end width-one" :class="[errors.amount ? 'is-invalid' : '']">
                            <span class="ms-1">₽</span>
                        </div>
                        <div v-if="errors.amount" class="d-block invalid-feedback">{{ errors.amount }}</div>
                    </div>
                </div>
                <div class="row mt-3">
                    <label class="col-4 col-form-label col-form-label-sm">{{ t('expense.category') }}</label>
                    <div class="col-8">

                        <div v-for="category in categoriesPop" :key="category.id">
                            <div v-if="category.id != 'new'" class="form-check">
                                <input
                                    :checked="category.checked"
                                    @change="setPopCategory(category.id)"
                                    name="category-pop"
                                    class="form-check-input"
                                    type="radio"
                                    :id="'category-' + category.id"
                                >
                                <label class="form-check-label" :for="'category-' + category.id">
                                    {{ category.title }}
                                </label>
                            </div>
                            <div v-else>
                                <div class="d-flex align-items-center">
                                    <div class="form-check d-flex align-items-center">
                                        <input
                                            :checked="category.checked"
                                            @change="setPopCategory(category.id)"
                                            class="form-check-input"
                                            type="radio"
                                            name="category-pop"
                                            id="category-new"
                                        >
                                        <label class="form-check-label" for="category-new"></label>
                                    </div>
                                    <div>
                                        <input
                                            class="form-control form-control-sm add-category"
                                            type="text"
                                            @focus="setPopCategory('new')"
                                            :placeholder="t('expense.add_category')"
                                            v-model="newCategory"
                                        >
                                    </div>

                                </div>
                                <div v-if="errors.expense_category_title" class="invalid-feedback d-block">{{ errors.expense_category_title }}</div>
                            </div>
                        </div>

                        <select v-if="categoriesOther.length" v-model="expense.expense_category_id" class="form-select form-select-sm mt-2" :class="[errors.expense_category_id ? 'is-invalid' : '']">
                            <option v-for="category in categoriesOther" :key="category.id" :value="category.id" :disabled="category.disabled">{{ category.title }}</option>
                        </select>
                        <div v-if="errors.expense_category_id" class="invalid-feedback">{{ errors.expense_category_id }}</div>
                    </div>
                </div>
                <div class="row mt-3">
                    <label class="col col-form-label col-form-label-sm">{{ t('expense.comment') }}</label>
                    <div class="col-8">
                        <textarea v-model="expense.comment" class="form-control form-control-sm" :class="[errors.comment ? 'is-invalid' : '']"></textarea>
                        <div v-if="errors.comment" class="invalid-feedback">{{ errors.comment }}</div>
                    </div>
                </div>
                <div class="row mt-3">
                    <label class="col-4 col-form-label col-form-label-sm">{{ t('expense.apartments') }}</label>
                    <div class="col-8">
                        <div class="mb-1">
                            <label class="form-check-label">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :checked="checkedAll"
                                    @change="setCheckedAll">
                                {{ t('expense.apartments_select_all') }}
                            </label>
                        </div>
                        <div class="mb-1">
                            <input v-model.trim="search" type="search" class="form-control form-control-sm" :placeholder="t('expense.apartments_placeholder')">
                        </div>
                        <div id="apartments-list">
                            <div v-for="apartment in apartmentsList" :key="apartment.id" class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :value="apartment.id"
                                    :id="'expense-apartment-' + apartment.id"
                                    :checked="apartment.checked"
                                    @change="setApartmentChecked(apartment)"
                                >
                                <label
                                    class="form-check-label"
                                    :for="'expense-apartment-' + apartment.id"
                                >
                                    {{ apartment.title }}
                                </label>
                            </div>
                        </div>
                        <div v-if="errors.apartment_ids" class="invalid-feedback d-block">{{ errors.apartment_ids }}</div>
                    </div>
                </div>
            </div>

        </template>
    </Modal>
</template>
<script>
    import { useI18n } from 'vue-i18n'
    import { ref, reactive, computed, onMounted, watch } from 'vue';
    import api from '@/common/api';
    import commonStore from '@/common/commonStore';
    import Modal from '@/common/components/Modal.vue';
    import DatePicker from "@/common/components/DatePicker";
    import dateHelper from "@/common/helpers/dateHelper";
    export default {
        name: 'Expense',
        components : {
            DatePicker, Modal
        },
        setup( ) {
            const { t } = useI18n();
            const user = computed(() => commonStore.state.user);
            const root = ref(null);
            const DatePickerDate = ref(null);
            const search = ref('');
            const isSaving = ref(false);
            const checkedAll = ref(null);
            const newCategory = ref(null);

            const setCheckedAll = e => {
                checkedAll.value = e.target.checked;
                apartments.value.forEach(a => {
                    a.checked = checkedAll.value;
                });
            }

            const setApartmentChecked = apartment => {
                apartment.checked = !apartment.checked;
                let all = true;
                apartments.value.forEach(a => {
                    if (!a.checked) all = false;
                });
                checkedAll.value = all;
            }

            const apartments = ref([...user.value.agency.apartments.map(a => ({
                id : a.id,
                checked : false,
                title : a.title_or_address,
            }))]);

            const categories = ref([...user.value.agency.expense_categories.map(a => ({
                    id : a.id,
                    title : a.name,
                    disabled : false,
                }))
            ]);

            const categoriesPop = computed(() => {
                return [...categories.value.slice(0, 2),  {id: 'new', title: null, checked: categories.value.length == 0}];
            });

            const categoriesOther = computed(() => {
                if (categories.value.length > 2) {
                    const list = [...categories.value.slice(2)];
                    list.sort((a, b) => a.title.localeCompare(b.title));
                    return [{id: null, title : t('expense.category_placeholder'), disabled : true}, ...list];
                } else {
                    return [];
                }
            });

            const expenseEmpty = {
                paid_date: null,
                amount: null,
                expense_category_id: 0,
                comment: null,
            }
            const expense = reactive({...expenseEmpty});

            const setExpenseEmpty = () => {
                expense.paid_date = new Date();
                expense.apartment_expenses_attributes = [];
                for (let k in expense) {
                    if (['paid_date', 'apartment_expenses_attributes'].indexOf(k) == -1) {
                        expense[k] = null;
                    } else if (['expense_category_id'].indexOf(k) > - 1) {
                        expense[k] = 0;
                    }
                }
            };
            onMounted(() => {
                setExpenseEmpty();
                DatePickerDate.value.setDate(expense.paid_date);
            })

            const errors = reactive({});

            const clearErrors = () => {
                for (let k in errors) {
                    errors[k] = null;
                }
            }

            const open = () => {
                root.value.open();
                setExpenseEmpty();
            }

            const modalClose = () => {};

            const buttons = computed(() => [
                {
                    id : 'save',
                    title : isSaving.value ? t('expense.saving') : t('expense.save'),
                    class : 'btn-primary',
                    disabled : isSaving.value,
                },
                {
                    id : 'cancel',
                    title : t('expense.cancel'),
                    class : 'btn-secondary',
                },
            ]);

            const clickButton = button => {
                if (button.id == 'cancel') {
                    root.value.close();
                } else if (button.id == 'save') {
                    save();
                }
            };

            const apartmentsList = computed(() => {
                return apartments.value.filter(a => {
                    if (search.value == '' || a.title.toLowerCase().indexOf(search.value.toLowerCase()) > -1) {
                        return true;
                    }
                    return false;
                });
            });


            const save = async () => {
                clearErrors();

                let categoryId = null;
                let categoryTitle = null;
                if (expense.expense_category_id) {
                    categoryId = expense.expense_category_id;
                } else {
                    const pop = categoriesPop.value.find(c => c.checked);
                    if (pop) {
                        if (pop.id == 'new') {
                            categoryTitle = newCategory.value;
                        } else {
                            categoryId = pop.id
                        }
                    }
                }

                isSaving.value = true;

                try {
                    await api.post({
                      url: 'expenses',
                      data: {
                          expense: {
                              paid_date: dateHelper.formatDateString(DatePickerDate.value.getData().date),
                              amount: expense.amount,
                              expense_category_id: categoryId,
                              expense_category_title: categoryTitle,
                              comment: expense.comment,
                              apartment_ids: apartments.value.filter(a => a.checked).map(a => a.id),
                          },
                      },
                    })
                    root.value.close();
                } catch (err) {
                    if (err.isValidation) {
                        for (let i in err.errors) {
                            errors[i] = err.errors[i][0];
                        }
                    }
                } finally {
                    isSaving.value = false;
                }
            }

            const setPopCategory = id => {
                categoriesPop.value.map(c => {
                    c.checked = c.id == id;
                });
                if (id !== null) {
                    expense.expense_category_id = null;
                }
            }

            watch(() => expense.expense_category_id, () => {
                if (expense.expense_category_id !== null) {
                    categoriesPop.value.map(c => c.checked = false);
                }
            })

            return {
                t,
                root,
                buttons,
                clickButton,
                open,
                modalClose,
                DatePickerDate,
                apartmentsList,
                categoriesPop,
                categoriesOther,
                expense,
                errors,
                search,
                checkedAll,
                setCheckedAll,
                setApartmentChecked,
                setPopCategory,
                newCategory
            }
        },
    }
</script>
<style scoped>
    #apartments-list {
        height: 150px;
        overflow: scroll;
        padding-left: 8px;
    }
    .width-one {
        width: 161px;
    }
    .add-category {
        width: 250px;
    }
</style>
