<template>
  <div :class="props.className?.wrapper">
    <div class="row">
      <label
        v-if="props.label"
        :for="id"
        class="col-form-label-sm py-0"
        :class="[props.className?.label ?? 'col-4', {'label-required': props.required}]"
      >
        {{ props.label }}
      </label>
      <div :class="props.className?.select ?? 'col-8'">
        <select
          v-model="selectValue"
          class="form-select form-select-sm me-1"
          :id="id"
          :disabled="props.disabled"
          :class="{'input-error': props.error}"
        >
          <option
            v-if="props.blankOptionText"
            value=""
          >
            {{ props.blankOptionText }}
          </option>
          <option
            :key="option[props.valueKey]"
            :value="option[props.valueKey]"
            v-for="option in props.options"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <div
        v-if="hasAfter"
        class="lh-0"
        :class="props.className?.after"
      >
        <slot name="after"/>
      </div>
    </div>
    <div
      class="row"
      v-if="props.error"
    >
      <div :class="props.className?.label ?? 'col-4'"/>
      <p
        class="small color-error my-1"
        :class="props.className?.select ?? 'col-8'"
      >
        {{ props.error }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUpdate, ref, useSlots } from 'vue';
import stringHelper from '@/common/helpers/stringHelper';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number, Boolean],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  blankOptionText: {
    type: String,
    default: '',
  },
  className: {
    type: Object,
  },
  required: {
    type: Boolean,
    default: false,
  },
  valueKey: {
    type: String,
    default: 'id',
  },
  error: {
    type: [String, Boolean],
    default: '',
  },
});

const slots = useSlots();
const hasAfter = ref(!!slots.after);

const id = stringHelper.getRandomUUID();

const emit = defineEmits(['update:value']);

const selectValue = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('update:value', value);
  },
});

onBeforeUpdate(() => {
  hasAfter.value = !!slots.after;
});
</script>

<style scoped>
.form-select {
  cursor: pointer;
}
</style>
