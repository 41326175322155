import * as amplitude from '@amplitude/analytics-browser';
import commonStore from '@/common/commonStore';
import useEnsurePromise from '@/common/composables/useEnsurePromise';
import authStore from '@/auth/store/authStore';

const isActive = process.env.VUE_APP_AMPLITUDE_IS_ACTIVE === 'true';

const EVENT = {
    // CHESSMATE
    // Пользователь увидел шахматку
    CHESSMATE_VIEW: 'Chessmate__View',
    // Пользователь увидел шахматку партнеров
    CHESSMATE_PARTNERS_VIEW: 'Chessmate__Partners__View',
    // Пользователь групповое редактирование
    CHESSMATE_GROUP_VIEW: 'Chessmate__Group_edit_price__View',
    // Пользователь увидел создание брони на шахматке
    CHESSMATE_BOOKING_CARD_NEW_VIEW: 'Chessmate__Booking_card_new__View',
    // Пользователь увидел редактирование брони на шахматке
    CHESSMATE_BOOKING_CARD_EDIT_VIEW: 'Chessmate__Booking_card_edit__View',
    // Пользователь увидел вкладку "Настройка спец. условий"
    CHESSMATE_BOOKING_CARD_EDIT_SPECIAL_PRICES__VIEW: 'Chessmate__Booking_card_edit_special_prices__View',
    // Пользователь кликнул на переход к старой шахматке
    CHESSMATE_OLD_CLICK: 'Chessmate_Old__Click',

    // APARTMENT
    // Пользователь увидел описание объекта
    APARTMENT_EDIT_DESCRIPTION_VIEW: 'Apartment__edit_Description__View',
    // Пользователь увидел редактирование фото
    APARTMENT_EDIT_PHOTO_VIEW: 'Apartment__edit_Photo__View',
    // Пользователь увидел редактирование характеристик
    APARTMENT_EDIT_FEATURES_VIEW: 'Apartment__edit_Features__View',
    // Пользователь увидел редактирование цен
    APARTMENT_EDIT_PRICES_VIEW: 'Apartment__edit_Prices__View',
    // Пользователь увидел редактирование удобств
    APARTMENT_EDIT_FACILITIES_VIEW: 'Apartment__edit_Facilities__View',
    // Пользователь увидел редактирование служебных настроек
    APARTMENT_EDIT_SERVICE_VIEW: 'Apartment__edit_Service__View',
    // Пользователь увидел историю изменений
    APARTMENT_EDIT_HISTORY_VIEW: 'Apartment__edit_History__View',
    // Пользователь увидел архивирование
    APARTMENT_EDIT_ARCHIVE_VIEW: 'Apartment__edit_Archive__View',

    // BASKET
    // Пользователь увидел корзину
    BASKET_VIEW: 'Basket__View',
    // Пользователь увидел историю корзины
    BASKET_HISTORY_VIEW: 'Basket__History__View',
    // Пользователь нажал на ссылку
    BASKET_HISTORY_ITEM_APPLY: 'Basket__History__Item__Apply',
    // Пользователь сформировал ссылку на варианты
    BASKET_GETLINK: 'Basket__getlink',
    // Пользователь отправил корзину
    BASKET_SEND: 'Basket__send',

    // NOTIFICATIONS
    // Пользователь увидел маленькое окно последних уведомлений
    NOTIFICATIONS_RECENT_VIEW: 'Notifications__Recent__View',
    // Пользователь увидел окно всех уведомлений
    NOTIFICATIONS_ALL_VIEW: 'Notifications__All__View',

    // CHANNEL MANAGER
    // Пользователь увидел главную раздела синхронизации
    CHANNEL_MANAGER_VIEW: 'Channel_manager__View',

    // SETTINGS
    // Пользователь увидел главную раздела настройки
    SETTINGS_MY_PROFILE_VIEW: 'Settings__My_profile__View',
    // Пользователь увидел раздел Баланс
    SETTINGS_BILLING_VIEW: 'Settings__Billing__View',
    // Пользователь увидел раздел Сотрудники
    SETTINGS_USERS_VIEW: 'Settings__Users__View',
    // Пользователь увидел раздел Прием платежей
    SETTINGS_PAYMENTS_SETTINGS_VIEW: 'Settings__Payments_settings__View',
    // Пользователь увидел раздел Модуль бронирования
    SETTINGS_BOOKING_MODULE_VIEW: 'Settings__Booking_module__View',

    // AUTH
    SIGN_UP_V2_STEP_1: 'SignUpV2_step1',
    SIGN_UP_V2_STEP_2: 'SignUpV2_step2',
    SIGN_UP_V2_STEP_3: 'SignUpV2_step3',
    SIGN_UP_V2_STEP_FINISH: 'SignUpV2_finish',

    PASSWORD_RECOVERY_STEP_2: 'PasswordRecovery_step2',
};

const { resolve: onSuccessIniting, reject: onErrorIniting, ensurePromise: initing } = useEnsurePromise();

const getInitOptions = () => ({
    // id пользователя
    userId: commonStore.getters.userId ?? authStore.getters.shortTempUserId,
    // версия приложения
    appVersion: process.env.VUE_APP_VERSION,
    // минимальная длина id
    minIdLength: 1,
    defaultTracking: {
        pageViews: false,
        attribution: false,
        formInteractions: false,
        fileDownloads: false,
    },
});

const getUserAdditionalProperties = () => {
    let properties = {
        // Устройство пользователя
        device: 'web',
    };

    if (commonStore.getters.userId) {
        properties = {
            ...properties,
            // Количество квартир сегментом
            apartments_segment: commonStore.getters.apartmentsSegment,
            // Количество квартир
            apartments_count: commonStore.getters.apartmentsCount,
            // номер агентства РК
            agency_id: commonStore.getters.agencyId,
            // роль пользователя
            role: commonStore.getters.userRole,
            // Система приема платежей
            payment_method: commonStore.getters.paymentMethods.join(', ') || 'not_connected',
        };
    }

    return properties;
};

const init = async () => {
    if (isActive) {
        try {
            const userAdditionalProperties = getUserAdditionalProperties();
            await amplitude.init(process.env.VUE_APP_AMPLITUDE_API_KEY, getInitOptions()).promise;
            const identifyEvent = new amplitude.Identify();

            for (const key in userAdditionalProperties) {
                identifyEvent.set(key, userAdditionalProperties[key]);
            }

            await amplitude.identify(identifyEvent).promise;
            onSuccessIniting();
        } catch {
            onErrorIniting();
        }
    }
};

const track = async (eventType, properties = null) => {
    if (isActive) {
        const params = {
            event_type: eventType,
        };

        if (properties) {
            params.event_properties = properties;
        }
        try {
            await initing;
            await amplitude.track(params).promise;
        } catch {
            //
        }
    }
};

export default {
    init,
    track,
    EVENT,
};
