import {i18n} from '@/common/init';

const parseDate = date => {
    if (typeof (date) === 'string') {
        const dateArray = date.split('-');
        if (dateArray.length == 3) {
            date = new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]));
        } else {
            date = new Date(date);
        }
    }
    return date;
}
const addToDate = (date, adds = []) => {
    let tempDate = new Date(date.getTime());
    adds.forEach(value => tempDate.setDate(tempDate.getDate() + value));
    return tempDate;
};
const daysDiff = (dateBegin, dateEnd) => {
    if (!dateBegin || !dateEnd) return null;
    return Math.abs(Math.round((new Date(dateBegin).getTime() - new Date(dateEnd).getTime()) / (1000 * 3600 * 24)));
};
const daysDiffTrunc = (dateBegin, dateEnd, abs = false) => {
    if (!dateBegin || !dateEnd) return null;
    const result = Math.trunc((new Date(dateBegin).getTime() - new Date(dateEnd).getTime()) / (1000 * 3600 * 24));
    return abs ? Math.abs(result) : result;
};
const formatMonthString = date => {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2);
}
const monthTitle = (date, capitalize = false, withYear = false) => {
    let title = date.toLocaleString(i18n.global.locale.value, {month: 'long'});
    title = capitalize ? title.charAt(0).toUpperCase() + title.slice(1) : title;
    if  (withYear) {
        title = title + ([0, 11].indexOf(date.getMonth()) == - 1 ? '' : (', '+date.getFullYear()));
    }
    return title;
}
const dateTitle = (date, withYear = true) => {
    if (typeof date === 'string') {
        date = parseDate(date);
    }
    if (withYear) {
        return date.toLocaleString(i18n.global.locale.value, {year: 'numeric', month: 'long', day: 'numeric'});
    } else {
        return date.toLocaleString(i18n.global.locale.value, {month: 'long', day: 'numeric'})
    }
}
const generateMonths = (year = null) => {
    let list = [];
    if (!year) {
        year = new Date().getFullYear();
    }
    for (let i = 0; i <= 11; i++) {
        const date = new Date(year, i, 3);
        list.push({id: i, value: formatMonthString(date), title: monthTitle(date, true)});
    }
    return list;
};
const generateFormattedDatesList = (dateBegin, dateEnd, includeBegin = true, uncludeEnd = true) => {
    if (dateBegin > dateEnd) return [];

    const daysCount = daysDiff(dateBegin, dateEnd);

    let list = [];
    for (let i = includeBegin ? 0 : 1; i <= daysCount - (uncludeEnd ? 0 : 1); i++) {
        list.push(formatDateString(addToDate(parseDate(dateBegin), [i])));
    }
    return list;
}
const formatDateString = date => {
    if (!date) return;
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)+ '-' + ('0' + (date.getDate())).slice(-2);
};

const getFullDateTime = date => {
    const d = new Date(date)
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const validateTimeString = (time) => {
    return /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
}

const viewDateString = (date, fullYear = true) => {
    if (!date) return '';
    const year = date.getFullYear().toString();
    const yearResult = fullYear ? year : year.slice(-2)
    return ('0' + (date.getDate())).slice(-2)  + '.' + ('0' + (date.getMonth() + 1)).slice(-2)+ '.' + yearResult;
}

const getDateOrDayDiffName = (date) => {
    const now = new Date();
    const diff = daysDiffTrunc(date, new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    if (diff === 0) {
        return i18n.global.t('days.today')
    } else if (diff === -1) {
        return i18n.global.t('days.yesterday')
    } else if (diff === 1) {
        return i18n.global.t('days.tomorrow')
    } else {
        return viewDateString(date, false);
    }
}

const getRelativeTime = time => {
    if (typeof time === 'string') {
        time = new Date(time);
    }
    let result;
    const timeTemp = new Date(time.getTime());
    if (new Date().setHours(0, 0, 0, 0) === timeTemp.setHours(0, 0, 0, 0)) {
        return time.toLocaleString(i18n.global.locale.value, { hour: '2-digit', minute: '2-digit' });
    } else {
        if (time.getFullYear() === new Date().getFullYear()) {
            result = time.toLocaleString(i18n.global.locale.value, { month: 'long', day: '2-digit' });
        } else {
            result = time.toLocaleString(i18n.global.locale.value, { month: 'long', day: '2-digit', year: 'numeric' });
        }
        return result.replace(' г.', '');
    }
};

const getTimeString = (time) => {
    const hours = Math.floor(time / 3600)
        .toString()
        .padStart(2, '0');
    const minutes = Math.floor((time % 3600) / 60)
        .toString()
        .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`;
}

export default {
    getRelativeTime,
    getFullDateTime,
    parseDate,
    formatDateString,
    viewDateString,
    formatMonthString,
    addToDate,
    generateMonths,
    generateMonthTitles : () => {
        return generateMonths().map(m => m.title);
    },
    generateFormattedDatesList,
    calculateDateBegin : (dateBegin, dateEnd) => {
        if (!dateEnd) return null;
        if (!dateBegin || dateBegin.getTime() > dateEnd.getTime() - 24 * 60 * 60 * 1000) {
            return new Date(dateEnd.getTime() - 24 * 60 * 60 * 1000);
        } else {
            return dateBegin;
        }
    },
    calculateDateEnd : (dateBegin, dateEnd) => {
        if (!dateBegin) return null;
        if (!dateEnd || dateEnd.getTime() < dateBegin.getTime() + 24 * 60 * 60 * 1000) {
            return new Date(dateBegin.getTime() + 24 * 60 * 60 * 1000);
        } else {
            return dateEnd;
        }
    },
    daysDiff,
    convertSearchToString : (date) => {
        return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
    },
    getMonth(date, type = 'current') {
        let monthDate = date;
        if (type == 'prev') {
            monthDate = date.getMonth() == 0 ? new Date(date.getFullYear() - 1, 11, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1);
        } else if (type == 'next') {
            monthDate = date.getMonth() == 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1);
        }
        return {
            value : formatMonthString(monthDate),
            title : monthTitle(monthDate, true, true),
        }
    },
    compareDates : (date1, date2) => {
        return formatDateString(date1) == formatDateString(date2);
    },
    dateTitle,
    validateTimeString,
    parseTimeString(time){
        return time && time.length >= 4 && validateTimeString(time)
    },
    dayTitleShort(date, firstUpper = false) {
        const title = date.toLocaleDateString(i18n.global.locale.value, { weekday: 'short' });
        return firstUpper ? title.charAt(0).toUpperCase() + title.slice(1) : title;
    },
    getDateOrDayDiffName,
    getTimeString,
}
