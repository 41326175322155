const bootstrap = require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';
import { nextTick, onMounted, onBeforeUnmount } from 'vue'
import emitter from '@/common/emitter';

export default function usePopover(root, popover, isOpened, on = {}) {
    const open = async (targetEl, placement = 'bottom', async = false, container = false, title = '') => {
        emitter.emit('openPopover');
        isOpened.value = true;
        await nextTick();

        const show = () => {
            popover.value = new bootstrap.Popover(targetEl, {
                content: root.value,
                container,
                title,
                placement: placement,
                html: true,
            });
            popover.value.show();
            if (on.afterShow) {
                on.afterShow();
            }
        }
        if (!async) {
            show();
        } else {
            setTimeout(show, 0);
        }
    }

    const close = () => {
        isOpened.value = false;
        if (popover.value) {
            popover.value.dispose();
            popover.value = null;
        }
        if (on.afterClose) {
            on.afterClose();
        }
    };

    const onDocumentClick = e => {
        if (popover.value
            && e.composedPath().find(item => item.classList && (item.classList.contains('popover'))) === undefined) {
            close();
        }
    };

    const onOpenPopover = () => {
        if (isOpened.value) {
            close();
        }
    }

    emitter.on('escape', () => {
        if (isOpened.value) {
            close();
        }
    });

    const setTitle = value => {
        const el = document.querySelector('.popover-header');
        if (el) {
            el.innerHTML = value;
        }
    }

    onMounted(() => {
        document.addEventListener('click', onDocumentClick);
        emitter.on('openPopover', onOpenPopover);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', onDocumentClick);
        emitter.off('openPopover', onOpenPopover);
    });

    return {
        open,
        close,
        setTitle,
    }
}
