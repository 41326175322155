import {createStore} from 'vuex';
import commonStore from '@/common/commonStore.js';
import { isAccess } from '@/common/rbac';
import useBaseNavigation from '@/common/composables/useBaseNavigation';
import InfoWidget from "@/common/infoWidget";

const channelManagerStore = createStore({
  state: {
    rowIsActive: false
  },
  getters: {
    invalidIntegrationsCount() {
      return commonStore.getters.hasFeatureFlag('channel_manager') ? commonStore.state.user.agency.invalid_integrations_count : 0
    }
  },
  mutations: {
    updateInvalidIntegrationsCount(_, payload) {
      commonStore.state.user.agency.invalid_integrations_count = payload
    }
  },
  actions: {
    async init() {
      commonStore.state.currentPage = 'channel_manager';
      const {ensureNavigateToPage} = useBaseNavigation();
      try {
        await commonStore.dispatch('init');
        await ensureNavigateToPage(isAccess('icalendar_sources'));
        commonStore.state.initPromiseResolve();
        InfoWidget.init();
      } catch (err) {
        if (err.isNoAccess) {
          window.location.href = err.goTo;
        }
        throw Error();
      }
    },
  }
});

export default channelManagerStore;
