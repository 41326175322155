import { createApp } from 'vue'
import App from '@/login/App.vue'
import ru from '@/common/i18n/ru.js'
import en from '@/common/i18n/en.js'
import sentry from '@/common/sentry';
import {init, initI18} from '@/common/init';

init.token();

const app = createApp(App);
app.use(initI18(ru, en));
sentry.init(app);
app.mount('#app');
