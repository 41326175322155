import {loadJSUrl} from "@/common/init";

const isActive = process.env.VUE_APP_INFOWIDGET_IS_ACTIVE === 'true';

const init = () => {
    if (!isActive) return;

    loadJSUrl('https://underchat.ru/api/widget', () => {}, () => {}, {
        defer: true
    });
}

export default {init};