//Правила для правильного склонения русскоязычных слов
import {createI18n} from 'vue-i18n';
import emitter from '@/common/emitter';
import stringHelper from '@/common/helpers/stringHelper';

const DB_KEY = 'db';
let i18n;
let currentLocale = localStorage.getItem('locale') ?? 'ru';

const i18nCustomRuleRu = (choice, choicesLength) => {
    let result;
    const choiceAbs = Math.abs(choice);
    if (choiceAbs == 0) {
        result = 0;
    } else if (choiceAbs == 1) {
        result = 1;
    } else if (choiceAbs >= 21 && choiceAbs % 10 == 1) {
        result = 2;
    } else if ( (choiceAbs >= 2 && choiceAbs <= 4) || (choiceAbs >= 22 && [2, 3, 4].indexOf(choiceAbs % 10) > - 1)) {
        result = 3;
    } else if (choiceAbs == 0 || (choiceAbs >= 5 &&  choiceAbs <= 20) || [5, 6, 7, 8, 9, 0].indexOf(choiceAbs % 10) > - 1) {
        result = 4;
    }
    return Math.min(result, choicesLength);
}

const loadCSSUrl = (url) => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
}

//Список загруженных скриптов
const loadedJSUrls = {};

//Загрузка любого скрипта
const loadJSUrl = (url, onSuccess = () => {}, onError = () => {}, options = {}) => {
    if (typeof loadedJSUrls[url] !== 'undefined') {
        if (loadedJSUrls[url]) {
            onSuccess();
        } else {
            onError();
        }
        return;
    }
    const scriptEl = document.createElement('script');
    scriptEl.onload = () => {
        loadedJSUrls[url] = true;
        onSuccess();
    }
    scriptEl.onerror = () => {
        loadedJSUrls[url] = false;
        onError();
    };
    if (options.defer) {
        scriptEl.defer = true;
    }
    scriptEl.setAttribute('src', url);
    document.head.appendChild(scriptEl);
}

//Параметры для карт
let loadedMap = null;
const yandexMapUrl = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.VUE_APP_YANDEX_MAP_API_KEY}&load=package.full&lang=${currentLocale}`;
const googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}&language=${currentLocale}&libraries=places`;

//Загрузка карты Google
const loadGoogleMap = (onSuccess, onError) => {
    loadJSUrl(googleMapUrl, onSuccess, onError);
};

//Загрузка карты Яндекс
const loadYandexMap = (onSuccess, onError) => {
    loadJSUrl(yandexMapUrl, () => {
        if (typeof window.ymaps !== 'undefined') {
            window.ymaps.ready(() => {
                onSuccess();
            });
        } else {
            onError();
        }
    }, () => {
        onError();
    });
};

//Впервую очередь идет загрузка Яндекс, если не получается, то грузится Google
const loadMap = (onSuccess, onError) => {
    if (loadedMap) {
        onSuccess(loadedMap);
    } else if (loadedMap === false) {
        onError();
    } else {
        loadYandexMap(() => {
            loadedMap = 'yandex';
            onSuccess(loadedMap);
        }, () => {
            loadGoogleMap(() => {
                loadedMap = 'google';
                onSuccess(loadedMap);
            }, () => {
                loadedMap = false;
                onError();
            });
        });
    }
}

const colors = [
    {id: 'black', value : 'rgb(0, 0, 0)', alpha : 'rgba(0, 0, 0, 0.5)'},
    {id: 'purple', value : 'rgb(129, 0, 129)', alpha : 'rgba(129, 0, 129, 0.5)'},
    {id: 'biryza', value : 'rgb(139, 212, 255)', alpha : 'rgba(139, 212, 255, 0.5)'},
    {id: 'green', value : 'rgb(51, 204, 52)', alpha : 'rgba(51, 204, 52, 0.5)'},
    {id: 'grey', value : 'rgb(161, 161, 161)', alpha : 'rgba(161, 161, 161, 0.5)'},
    {id: 'orange', value : '#fada98', alpha : '#fada98'},
    {id: 'marmur', value : 'rgb(255, 172, 198)', alpha : 'rgba(255, 172, 198, 0.5)'},
    {id: 'brown', value : 'rgb(154, 102, 52)', alpha : 'rgba(154, 102, 52, 0.5)'},
    {id: 'red', value : 'rgb(254, 0, 0)', alpha : 'rgba(254, 0, 0, 0.5)'},
    {id: 'white', value : 'rgb(255, 255, 255)', alpha : 'rgba(255, 255, 255, 0.5)'},
    {id: 'blue', value : 'rgb(1, 77, 223)', alpha : 'rgba(1, 77, 223, 0.5)'},
    {id: 'yellow', value : 'rgb(255, 255, 1)', alpha : 'rgba(255, 255, 1, 0.5)'},
]

const colorsMap = {};
colors.forEach(c => colorsMap[c.id] = c.alpha);

const isObject = (value) => typeof value === 'object' && !Array.isArray(value) && value !== null;


const copyArray = (value) => JSON.parse(JSON.stringify(value));

const init = {
    token: () => {
        //Установка токена из GET-параметра
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        if (token) {
            localStorage.setItem('token', token);
        }
    },
}

const initI18 = (ru, en) => {
    i18n = createI18n({
        legacy: false,
        locale: currentLocale,
        fallbackLocale: 'ru',
        messages: { ru, en},
        pluralRules: {
            ru: i18nCustomRuleRu
        },
    });

    emitter.on('updateCurrency', (agencyCurrency) => {
        // https://vue-i18n.intlify.dev/guide/essentials/syntax#linked-messages
        // При вызове добавляем в корень переводов message с глифом валюты
        // в переводах обращаемся через нативный метод @.[modifier]:[key]
        // в случае с валютой @:agencyCurrency
        // есть узкий момент из-за использования такого подхода - в корне ЗАПРЕЩЕНО делать перевод с ключом agencyCurrency
        // из-за этого используется ключ в camelCase нотации(защита от дурака, т.к. в переводах для ключей используется snake_case)

        ['ru', 'en'].forEach(locale => {
            i18n.global.mergeLocaleMessage(locale, {
                agencyCurrency
            })
        });
    })

    // Используется для вставки текста с html тегами и стилями
    //    Как работать:
    //      в переводе на {currency} и получите {price} в {br} подарок {description}
    //      в шаблоне t_html('some.key', {var: {tag: 'b', format: 'currency', value: item.price, formattedContent: '- {value}'}})
    //      ОБРАТИТЬ ВНИМАНИЕ на то, что {br} нужно размещать в тексте, но в options они не передаются.
    //      tag - имя html тега
    //      value - значение, которое нужно вставить в html разметки
    //      formattedContent - то как должен на выходе должно выглядеть содержимое
    //      attrs - набор атрибутов для тега
    //

    i18n.global.t_html = (key, options) => {
        let string = stringHelper.getNestedValue(i18n.global.getLocaleMessage(i18n.global.locale.value), key.split('.'));

        string = string.replace(/[\u00A0-\u9999<>&]/g, i => '&#' + i.charCodeAt(0) + ';');

        // Отдельно обрабатываются переносы
        string = string.replaceAll('{br}', '<br/>');
            for (let key in options) {

                let {tag, formattedContent, value, attrs} = options[key];

                const pattern = /\{([^{}]+)\|{([^}]+)}\}/; // если в строке перевода есть паттерн для вставки текста {текст|{peremennaya}}
                const match = string.match(pattern);

                if (match && match[2] === key) {
                    string = string.replace(match[0], `{${match[2]}}`)
                    value ??= match[1];// если передано value, то игнорируем то, что внутри перевода
                }

                if (formattedContent) {
                    formattedContent = formattedContent.replace('{value}', value);
                } else {
                    formattedContent = value;
                }

                const element = document.createElement(tag);
                element.innerHTML = formattedContent;

                for (let attr in attrs) {
                    element[attr] = attrs[attr];
                }

                if (value) {
                    string = string.replace(`{${key}}`, element.outerHTML);
                } else {
                    string = string.replace(`{${key}}`, '');
                }
            }

        return string;
    }

    return i18n;
}

const changeLocale = () => {
    if (i18n.global.locale.value === 'ru') {
        localStorage.setItem('locale', 'en')
    } else {
        localStorage.setItem('locale', 'ru')
    }

    location.reload();
}

const dbData = () => {
    let data = localStorage.getItem(DB_KEY);
    if (data) {
        data = JSON.parse(data);
        if (data) {
            return data;
        }
    }
    return {};
}
const dbGet = (key, defaultValue = undefined) => {
    const data = dbData();
    return typeof data[key] !== 'undefined' ? data[key] : defaultValue;
}
const dbSet = (key, value) => {
    const data = dbData();
    data[key] = value;
    localStorage.setItem(DB_KEY, JSON.stringify(data));
}

export { initI18, loadCSSUrl, loadJSUrl, loadMap, colors, colorsMap, isObject, copyArray, dbGet, dbSet, init, i18n, changeLocale }

