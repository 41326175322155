export default {
    status: {
        save_success: 'Данные успешно сохранены',
        save_error: 'Ошибка при сохранении данных',
        sms_sent: 'SMS сообщение с кодом подтверждения отправлено',
        sent_error: 'Ошибка при отправке кода подтверждения',
        loading_error: 'Ошибка при загрузке данных',
        data_copied: 'Данные скопированы',
        password_restore_request_success: 'Письмо для на восстановление пароля отправлено Вам на почту',
        password_restore_request_error: 'Ошибка при отправке письма для восстановления пароля',
        payment_remove_success: 'Платежная система "{name}" отключена',
        payment_remove_error: 'Ошибка отключения платежной системы "{name}"',
        webhook_added: 'Webhook успешно добавлен',
        webhook_removed: 'Webhook успешно удален',
        webhook_added_error: 'Ошибка добавления Webhook',
        webhook_removed_error: 'Ошибка удаления Webhook',
        integration_launched: 'Интеграция успешно запущена',
        integration_stopped: 'Интеграция успешно остановлена',
        integration_launched_error: 'Ошибка запуска интеграции',
        integration_stopped_error: 'Ошибка остановки интеграции',
        source_archived: 'Источник добавлен в архив',
        source_recovered: 'Источник восстановлен из архива',
        source_archived_error: 'Ошибка при архивации источника',
        source_recovered_error: 'Ошибка при восстановлении источника',
        source_removed: 'Источник удален',
        source_removed_error: 'Ошибка при удалении источника',
        source_added: 'Источник добавлен',
        season_removed: 'Сезон удален',
        season_removed_error: 'Ошибка удаления сезона',
        balance_redirect_error: 'Ошибка перехода на страницу оплаты',
        user_is_locked: 'Пользователь {user} заблокирован',
        user_is_locked_error: 'Ошибка блокировки пользователя: {user}',
        user_is_unlocked: 'Пользователь {user} восстановлен',
        user_is_unlocked_error: 'Ошибка восстановления пользователя: {user}',
        user_invited: 'Приглашение отправлено',
        user_invited_error: 'Ошибка отправки приглашения',
        email_confirmation_canceled: 'Подтверждение нового Email отменено',
        metro_loading_error: 'Ошибка загрузки списка станций метро',
        district_loading_error: 'Ошибка загрузки списка районов',
        season_remove_success: 'Вкладка "{title}" удалена',
        season_remove_error: 'Ошибка при удалении вкладки "{title}"',
        photo_remove_error: 'Ошибка при удалении фотографии',
        archived_success: 'Объект "{name}" добавлен в архив',
        restored_success: 'Объект "{name}" восстановлен из архива',
        archived_error: 'Ошибка при добавлении объекта "{name}" в архив',
        restored_error: 'Ошибка при восстановлении объекта "{name}" из архива',
        data_update_error: 'Ошибка обновления данных',
        photo_upload_error: 'Ошибка при загрузке файла: {name}'
    },
    statuses : {
        booked : 'Бронь',
        request : 'Заявка',
        canceled : 'Отмена',
        deleted : 'Удалено',
        additional_is_delete: '[удалено]',
    },
    left_side: {
        calendar : 'Календарь',
        clients_and_reservations: 'Клиенты и брони',
        partners : 'Партнеры',
        channel_manager : 'Менеджер каналов',
        settings : 'Настройки',
        faq : 'База знаний',
        black_list : 'Черный список',
        news_and_wishes : 'Новости и пожелания',
        statistics : 'Статистика',
    },
    form: {
        login: 'Вход',
        phone_or_email: 'Телефон или email',
        password: 'Пароль',
        remembe_me: 'Запомнить меня',
        login_button: 'Войти',
        registration: 'Регистрация',
        forgot_password: 'Забыли пароль?',
        no_sms: 'Не получили SMS код подтверждения?',
        no_email: 'Не получили письмо с инструкцией для подтверждения email адреса?',
        unknown_error: 'Неизвестная ошибка',
    },
    navbar : {
        cart : 'Корзина',
        add : 'Добавить',
        add_apartment : 'Добавить объект',
        add_expense : 'Добавить расход',
        settings : 'Настройки',
        logout : 'Выйти',
        logout_confirm : 'Выйти из системы?',
        return_to_old_chessmate: 'Вернуться к старой версии шахматки',
        login_by_manager: 'Войти под менеджером',
        work_time: '(с 8 до 20)'
    },
    messages_short_list : {
        no_messages : 'Сообщений нет.',
        new_count : 'нет новых сообщений | 1 новое сообщение | {count} новое сообщение | {count} новых сообщения | {count} новых сообщений',
        unread_count : 'Все сообщения | Все сообщения (1 непрочитанное) | Все сообщения ({count} непрочитанное) | Все сообщения ({count} непрочитанных) | Все сообщения ({count} непрочитанных)',
    },
    messages_full_list : {
        title : 'Все сообщения',
        read_all : 'Отметить как прочитанные',
        close_modal : 'Закрыть',
    },
    cart : {
        title : 'Отправить предложения по email и смс',
        title_link : 'Ссылка на варианты',
        history_load : 'История',
        history_table_date : 'Дата',
        history_table_phone : 'Телефон',
        history_table_email : 'Email',
        history_table_source : 'Источник',
        history_table_more : 'подробнее',
        get_link : 'Получить ссылку',
        send : 'Отправить',
        close : 'Закрыть',
        copy : 'Скопировать',
        clear : 'Очистить корзину',
        arrival : 'Заезд',
        departure : 'Выезд',
        email : 'Email',
        sms : 'СМС',
        buy_more : 'Купить еще',
        sms_left: 'Осталось {count} СМС',
        lifetime: 'Срок действия ссылки',
        lifetime_days: 'Без ограничений | 1 день | {count} день | {count} дня | {count} дней',
        amount: '@:agencyCurrency в сутки',
    },
    expense : {
        title : 'Добавление расхода',
        date : 'Дата',
        sum : 'Сумма',
        category : 'Статья расхода',
        category_placeholder : 'Показать еще статьи',
        add_category : 'Название новой статьи',
        comment : 'Комментарий',
        apartments : 'Квартиры',
        apartments_placeholder : 'Поиск по названию',
        apartments_select_all : 'Общие расходы агентства',
        cancel : 'Закрыть окно',
        save : 'Добавить расход',
        saving : 'Добавление расхода...',
        new_category_name : 'Название категории',
    },
    date_picker : {
        default: 'Не задано',
        invalid_time: 'Неправильный формат времени',
    },
    date_picker_simple : {
        default: 'Не задано',
    },
    tags: {
        tags_list: 'Список тегов',
        title: 'Доступные теги:',
        close: 'Закрыть',
        guest_name: 'Имя гостя',
        checkin_date: 'Дата заезда',
        checkout_date: 'Дата выезда',
        address: 'Адрес объекта',
        address_eng: 'Адрес объекта (транслит)',
        how_to_get: 'Как добраться (настраиватеся в карточке объекта)',
        manager_name: 'Имя менеджера по заселению (настраивается в карточке объекта)',
        manager_name_eng: 'Имя менеджера по заселению (транслит)',
        manager_phone: 'Телефон менеджера по заселению (настраивается в карточке объекта)',
        agency_name: 'Название агентства',
        agency_phone: 'Телефон агентства',
        agency_email: 'Электронная почта агентства',
        links_booking: 'Ссылка для предоплаты',
        links_booking_eng: 'Ссылка для предоплаты (страница на английском)',
        wait_time: 'Срок оплаты',
        is_airbnb: 'Вывод содержимого только для брони с Airbnb',
        is_booking_com: 'Вывод содержимого только для брони с Booking.com',
        is_ostrovok: 'Вывод содержимого только для брони с Ostrovok.ru',
        is_oktogo: 'Вывод содержимого только для брони с Oktogo.ru',
        is_mention_prepaid: 'Вывод содержимого только для брони с выставлением предоплаты',
        is_agency_prepaid_settings: 'Вывод содержимого только, если у агентства настроены платежи'
    },
    attributes : {
        default : 'Не важно',
        reset : 'Сбросить все удобства',
    },
    modal_seasons: {
        title_edit: 'Редактирование сезона',
        title_add: 'Добавление сезона',
        name: 'Название',
        season_start: 'Дата начала сезона',
        season_end: 'Дата окончания сезона',
        add: 'Добавить',
        added: 'Добавляется...',
        edit: 'Редактировать',
        edited: 'Редактируется...',
        close: 'Закрыть'
    },
    drop_zone: {
        drag_files: 'Перетащите файлы',
        to_temp: 'в выделенную область',
        choose_files: 'Выберите файлы',
        or_drag: 'или перетащите их в выделенную область',
        max_size: 'Максимальный размер файла: {count}Мб',
        types: 'Допустимые типы:',
        loaded: 'Изображений загружено: {from} из {to}'
    },
    alert_modal: {
        cancel: 'Отменить',
        confirm: 'Подтвердить'
    },
    time_picker: {
        reset: 'Сбросить'
    },
    text_editor: {
        heading: 'Заголовок {number}',
        save: 'Сохранить',
        add_link: 'Добавьте ссылку: ',
        visit_url: 'Перейти по ссылке:',
        edit: 'Редактировать',
        remove: 'Удалить'
    },
    filezone: {
        label: 'Выбрать файлы',
        max_count: 'Максимальное количество файлов: {count}'
    },
    days: {
        today: 'сегодня',
        tomorrow: 'завтра',
        yesterday: 'вчера',
    },
    unknown_error: 'Неизвестная ошибка, попробуйте заново',
    connection_error: 'Отсутствует сигнал интернета',
    timeout_error: 'Превышено время выполнения запроса, попробуйте заново',
    hide_password: 'Скрыть пароль',
    show_password: 'Показать пароль',
    custom_timer: 'Через {time} можно выслать код заново',
}
