<template>

    <div v-if="isSimple" class="input-group">
        <input
            v-model="value"
            ref="inputDate"
            :id="'date-picker-' + name"
            type="text"
            class="form-control input-date bg-white"
            :class="{'form-control-sm': isSmall}"
            :placeholder="t('date_picker.default')"
            @click="openPicker"
            :style="{maxWidth: maxDateWidth, height: isSmall ? '34px': 'auto'}"
            readonly
            :disabled="dateDisabled"
            autocomplete="off">
        <button class="btn btn-link border-btn text-decoration-none d-flex align-items-center" :class="{'btn-sm' : isSmall}" type="button" @click="clickButton">
            <span class="material-icons">date_range</span>
        </button>
    </div>
    <div v-else>
        <div class="row">
            <div v-if="title" class="col-auto text-end">
                <label :for="'date-picker' + name" class="col-form-label" :class="{'label-required': isRequired, 'form-label-sm': isSmall}">{{ title }}</label>
            </div>
            <div class="col-auto text-right pe-0">
                <div class="input-group">
                    <input
                        v-model="value"
                        ref="inputDate"
                        :id="'date-picker-' + name"
                        type="text"
                        readonly
                        :disabled="dateDisabled"
                        class="form-control input-date"
                        :class="{'form-control-sm': isSmall}"
                        @click="openPicker"
                        :style="{maxWidth: maxDateWidth, height: isSmall ? '31px': 'auto'}"
                        autocomplete="off">
                    <button
                            class="btn btn-link border-btn text-decoration-none d-flex align-items-center"
                            :class="{'btn-sm' : isSmall}" type="button" @click="clickButton"
                            :style="{height: isSmall ? '31px': 'auto'}"
                    >
                        <span class="material-icons">date_range</span>
                    </button>
                </div>
            </div>
            <div v-show="withTime" class="col-auto text-right pe-0">
              <TimePicker v-model:value="time" :disabled="timeDisabled" :step="15" ref="inputTime"/>
            </div>
        </div>
        <div v-if="totalError" class="invalid-feedback d-block">{{ totalError }}</div>
    </div>
</template>
<script>
    import flatpickr from "flatpickr";
    import 'flatpickr/dist/flatpickr.css';
    import { Russian } from "flatpickr/dist/l10n/ru.js"
    import { useI18n } from 'vue-i18n';
    import { ref, watch, computed } from 'vue';
    import dateHelper from "@/common/helpers/dateHelper";
    import TimePicker from '@/common/components/inputs/TimePicker.vue';
    export default {
        name: 'DatePicker',
        components: {TimePicker},
        emits: [
            'update'
        ],
        props : {
            isSimple: {
                default: false,
            },
            isSmall: {
                default: false,
            },
            isRequired: {
                default: false,
            },
            title: {
                default: null,
            },
            name: {
                required: true
            },
            withTime: {
                default: false,
            },
            maxDateWidth: {
                default : 'auto'
            },
            error : {
                default : null,
            },
            dateFormat: {
                default: 'd.m.Y'
            },
            dateDisabled: {
                type: Boolean,
                default: false,
            },
            timeDisabled: {
                type: Boolean,
                default: false,
            }
        },
        setup(props, { emit }) {
            const init = {
                value : '',
                time : null,
            };
            let picker = null;
            const { t, locale } = useI18n();
            const value = ref(init.value);
            const date = ref(new Date());
            const time = ref(init.time);
            const inputDate = ref(null);
            const inputTime = ref(null);
            const watchUpdate = ref(true);
            const timeError = computed(() => {
                if (props.withTime && !dateHelper.validateTimeString(time.value)) {
                    return t('date_picker.invalid_time');
                } else {
                    return null;
                }
            });
            const totalError = computed(() => {
                if (props.error) {
                    return props.error;
                } else {
                    return null;
                }
            });

            const reset = () => {
                value.value = init.value;
                date.value = new Date();
                time.value = init.time;
            }
            const clickButton = () => {
                inputDate.value.click();
            }
            const openPicker = e => {
                if (picker) {
                    picker.destroy();
                }
                const params = {
                    static: true,
                    defaultDate: date.value,
                    dateFormat: props.dateFormat,
                    onClose: (selectedDates, dateStr, instance) => {
                        picker = null;
                        date.value = instance.selectedDates[0];
                        value.value = dateStr;
                        instance.destroy();
                        if (inputDate.value) {
                          inputDate.value.blur();
                        }
                    }
                };
                if (locale.value == 'ru') {
                    params.locale = Russian;
                }
                picker = flatpickr(e.target, params).open();
            }

            const setDate = (newDate, newTime = null, noWatch = false) => {
                if (noWatch) {
                    watchUpdate.value = false;
                }
                value.value = props.dateFormat === 'd.m' ? dateHelper.viewDateString(newDate).slice(0,5) : dateHelper.viewDateString(newDate);
                if (picker) {
                    picker.setData(newDate);
                } else {
                    date.value = newDate;
                }
                if (newTime) {
                    time.value = newTime;
                }
                if (noWatch) {
                    setTimeout(() => watchUpdate.value = true);
                }
            }

            watch(date, () => {
                if (!watchUpdate.value) return;
                emit('update', {
                    [props.name] : value.value !== '' ? date.value : null,
                });
            });

            const getData = () => {
                const currentDate = value.value !== '' ? date.value : null;
                let currentTime = null;
                if (props.withTime) {
                    currentTime = timeError.value ? null : time.value;
                }
                return {
                    date: currentDate,
                    time: currentTime,
                }
            }

            return {
                t,
                value,
                time,
                inputDate,
                inputTime,
                reset,
                openPicker,
                clickButton,
                setDate,
                getData,
                totalError,
            }
        }
    }
</script>
<style scoped>
    .input-date {
        cursor: pointer;
    }
</style>
