import { createStore } from 'vuex';
import api from '@/common/api';
import emitter from '@/common/emitter';
import initCommonCable from '@/common/initCommonCable';
import channelManagerStore from '@/channelManager/channelManagerStore';
import sentry from '@/common/sentry';
import amplitude from '@/common/amplitude';
import { v4 as uuidV4 } from 'uuid';

const commonStore = createStore({
    state: {
        currentPage: undefined,
        initPromiseResolve: null,
        initPromise: null,
        user: null,
        tempUserId: null,
        lastMessages: [],
        unreadMessagesCount: 0,
        defaultCoordinates: {
            lat: 55.751895,
            lon: 37.622386,
        },
        loading: [],
        smsCount: 0,
        apiRequests: {},
        isFullNavbar: true,
    },
    getters: {
        isManager(state) {
            return state.user.is_manager === true;
        },
        isRubleCurrency(state) {
            return state.user.agency.currency === '₽';
        },
        isLoading(state) {
            return state.loading.length !== 0;
        },
        hasFeatureFlag(state) {
            return flag => {
                if (state.user) {
                    if (flag in state.user.feature_flags_hash) {
                        return state.user.feature_flags_hash[flag];
                    } else {
                        return true;
                    }
                }
            }
        },
        isShowPrices(state) {
            return state.user.chessmate.show_prices;
        },
        isAlertMessage(state) {
            return state.user.agency.alert?.type == 'info';
        },
        isAlertBlock(state) {
            return state.user.agency.alert?.type == 'blocked';
        },
        urlForPay(state, getters) {
            return getters.hasFeatureFlag('settings_v2') ? '/settings/#/billing' : `/agencies/${getters.agencyId}?replenish=1#payment`;
        },
        urlAddApartment(state, getters) {
            if (getters.hasFeatureFlag('apartment_v2')) {
                return '/apartment/';
            } else {
                return state.user ? `/agencies/${getters.agencyId}/apartments#apartments/new` : null;
            }
        },
        getUrlEditApartment(state, getters) {
            return apartmentId => {
                if (getters.hasFeatureFlag('apartment_v2')) {
                    return `/apartment/#/${apartmentId}/`;
                } else {
                    return '/agencies/{AGENCY_ID}/apartments#apartments/{APARTMENT_ID}/edit'
                        .replace('{AGENCY_ID}', getters.agencyId)
                        .replace('{APARTMENT_ID}', apartmentId)
                }
            }
        },
        agencyBalance(state) {
            return state?.user?.agency?.account?.balance;
        },
        agencyDateUp(state) {
            return state?.user?.agency?.alert?.date_up;
        },
        agencyNumber(state) {
            return state?.user?.agency?.account?.number;
        },
        userId(state) {
            return state?.user?.id;
        },
        tempUserId(state) {
            return state.tempUserId;
        },
        statusColors(state) {
            return state.user.agency.status_colors;
        },
        getStatusColor(state) {
            return color => {
                return state.user.agency.status_colors[color] ?? null;
            }
        },
        invalidIntegrationsCount() {
            return channelManagerStore.getters.invalidIntegrationsCount;
        },
        monetaCommission(state) {
            const commissionPercent = state.user.constants.moneta.commission_percent;
            const commissionBase = state.user.constants.moneta.commission_base;

            return `${commissionPercent}% ${commissionBase !== 0 ? `+ ${commissionBase}₽` : ''}`;
        },
        monetaWithdrawComission(state) {
            const commissionPercent = state.user.constants.moneta.withdraw_commission_percent;
            const commissionBase = state.user.constants.moneta.withdraw_commission_base;

            return `${commissionPercent}% ${commissionBase !== 0 ? `+ ${commissionBase}₽` : ''}`;
        },
        hasApiActiveRequestsWithAlert(state) {
            return Object.keys(state.apiRequests).some(i => state.apiRequests[i].onRedirect === api.ACTIONS_ON_REDIRECT.ALERT);
        },
        userApartmentIds(state) {
            return state.user.agency.apartments.map(a => a.id);
        },
        apartmentsCount(state) {
            return state.user.agency.apartments_count;
        },
        apartmentsSegment(_, getters) {
            const apartmentsCount = getters.apartmentsCount;
            const sizeRanges = {
                'XS': 2,
                'S': 4,
                'M': 11,
                'L': 20,
                'XL': Infinity
            }

            for (const range in sizeRanges) {
                if (apartmentsCount <= sizeRanges[range]) {
                    return range;
                }
            }
        },
        agencyId(state) {
            return state.user.agency.id;
        },
        userRole(state) {
            return state.user.role;
        },
        paymentMethods(state) {
            return state.user.agency.payment_methods;
        },
        agencyCurrency(state) {
            return state.user.agency.currency;
        },
    },
    mutations: {
        createLoading(state, payload) {
            if (!state.loading.includes(payload)) {
                state.loading.push(payload);
            }
        },
        removeLoading(state, payload) {
            const index = state.loading.indexOf(payload);
            if (index !== -1) {
                state.loading.splice(index, 1);
            }
        },
        setLastMessages(state, payload) {
            state.lastMessages = [...payload.messages.slice(0, 5)];
        },
        setUnreadMessagesCount(state, payload) {
            state.unreadMessagesCount = payload.count;
        },
        setMessageRead(state, payload) {
            if (state.unreadMessagesCount > 0) {
                state.unreadMessagesCount = state.unreadMessagesCount - 1;
            }
            state.lastMessages = [...state.lastMessages.map(m => {
                if (m.id == payload.id) {
                    m.read = true;
                }
                return m;
            })];
        },
        setAllMessagesRead(state) {
            state.unreadMessagesCount = 0;
            state.lastMessages = [...state.lastMessages.map(m => {
                m.read = true;
                return m;
            })];
        },
        updateDepositBalance(state, payload) {
            state.user.agency.account.deposit_balance = payload
        },
        updateInvalidIntegrationsCount(_, payload) {
            channelManagerStore.commit('updateInvalidIntegrationsCount', payload)
        },
        setSMSCount(state, payload) {
            state.smsCount = payload;
        },
        toggleNavbar(state) {
            state.isFullNavbar = !state.isFullNavbar;
            localStorage.setItem('isFullNavbar', state.isFullNavbar);
        },
        setCommonError(state, payload) {
            state.commonError = payload;
        },
        initTempUserId(state) {
            state.tempUserId = uuidV4();
        },
    },
    actions: {
        async loadUser({state, getters}) {
            try {
                state.user = await api.get('info');
                localStorage.setItem('agency_id', getters.agencyId);
                emitter.emit('updateCurrency', getters.agencyCurrency);
            } catch {
                throw Error();
            }
        },
        async init({state, dispatch}) {
            try {
                await dispatch('loadUser');
                dispatch('loadLastMessages');
                dispatch('loadSMSCount');

                sentry.setUser(state.user);
                amplitude.init();
                initCommonCable();
            } catch {
                throw Error();
            }
        },
        async loadLastMessages({commit}) {
            try {
                const data = await api.get('messages');
                commit('setLastMessages', {messages: data.messages,});
                commit('setUnreadMessagesCount', {count: data.unread_count});
            } catch {
                //
            }
        },
        async saveMessageRead({commit}, payload) {
            commit('setMessageRead', {id: payload.id});
            try {
                await api.put('messages/' + payload.id + '/read');
            } catch {
                throw Error();
            }
        },
        async loadSMSCount({commit}) {
            try {
                const data = await api.get('info/sms_limit');
                commit('setSMSCount', data.sms_limit);
            } catch {
                //
            }
        }
    }
});

commonStore.state.initPromise = new Promise(resolve => commonStore.state.initPromiseResolve = resolve);

export default commonStore;
